import React from 'react';
import { List, Filter, TextInput } from 'react-admin';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="headline" />
        </Filter>
    );
};

const Listing = ({ children, ...props }) => (
    <List filters={<Filters />} sort={{ field: 'last_modified', order: 'DESC' }} {...props}>
        {children}
    </List>
);

export default Listing;
