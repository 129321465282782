import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';
import shouldUpdate from 'recompose/shouldUpdate';
import compose from 'recompose/compose';

const EditToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            redirect="edit"
            submitOnEnter
        />
    </Toolbar>
);

const enhance = compose(
    shouldUpdate((props, nextProps) => false)
);

export default enhance(EditToolbar);
