import Icon from '@material-ui/icons/Check';
import List from './List';

const name = 'AppleNewsDryRun';
let label = 'Apple News Dry Run';

export {
    List,
    Icon,
    label,
    name,
};