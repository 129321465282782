import AddBoxIcon from '@material-ui/icons/AddBox';
import React from 'react';
import { CardActions, ListButton, ShowButton } from 'react-admin';
import FiltersButton from '../../components/FiltersButton';
import PauseButton from "../../components/PauseButton";
import RawPreview from './RawPreview';
import SiteLink from "./SiteLink";

export const required = [
    'headline',
    'locale',
    'feed_type',
    'feed_document_type',
    // solr fields
    'site',
    'articleLanguage',
    'articleTerritory',
];

export const customTemplates = [
    'feed_template',
    'item_template',
    'item_separator',
    'item_formative',
    'content_type',
];

export const customTemplateGuid = [
    'custom_template_guid',
];

export const contentSyndicationcontents = [
    'content_syndication_control_tag',
    'content_syndication_tag_handle',
    'content_syndication_domain'
];

export const editorsFilters = [
    'articleCategoryHandle',
    'articleProductHandle',
    'articleTagHandle',
    'articleType',
    'articleVerticalHandle',
];

export const customSolrFl= [
    'custom_filter'
];


const EditActions = ({ basePath, data, resource, ...props }) => (
    <CardActions>
        {data && data.id && resource === 'feeds' ? (
            <PauseButton record={data} />
        ) : null
        }
        <RawPreview record={data} minVersion={false} />
        <SiteLink record={data} />
        <RawPreview record={data} prettify={true} />
        <FiltersButton
            label="Feed options"
            icon={iconClasses => <AddBoxIcon style={{ fontSize: 20 }} className={iconClasses.leftIcon} />}
            exclude={[
                ...required,
                ...window.solr_indexed_fields, 'q', 'account', 'video_id', 'playlist_id', 'thread_id', 'jwpplayer'
            ]}
            groups={{
                custom_fl_group: customSolrFl,
                custom_template_guid_group: customTemplateGuid,
                custom_template: customTemplates,
                content_syndication: contentSyndicationcontents
            }}
            isAside={false}
        />
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} record={data} />
    </CardActions>
);

export default EditActions;