import Icon from '@material-ui/icons/DeleteSweep';
import List from './List';

const name = 'ftecache';

export {
    List,
    Icon,
    name,
};
