import React from 'react';
import { Edit as BaseEdit } from 'react-admin';
import EditActions from './EditActions';

const Edit = ({ children, ...props }) => (
    <BaseEdit undoable={false} {...props}>
        {children}
    </BaseEdit>
);

Edit.defaultProps = {
    actions: <EditActions />,
};

export default Edit;
