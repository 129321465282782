import Icon from '@material-ui/icons/Forum';
import List from './List';
import Show from './Show';

const name = 'forums';

export {
    List,
    Show,
    Icon,
    name,
};