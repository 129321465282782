import React from 'react';
import { Datagrid, UrlField, ImageField, TextField, Filter, TextInput, SelectInput } from 'react-admin';
import List from '../List';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SelectInput
                source="site"
                alwaysOn
                label={"Site"}
                choices={window.sitesChoices}
            />
            <TextInput source="model_name" alwaysOn label={"Model name"}/>
        </Filter>
    );
};
const ShoppingList = (props) => {
    return (
        <List filters={<Filters  />}  {...props} sort={{ field: 'date', order: 'DESC' }} >
            <Datagrid>
                <TextField source="product_key" />
                <ImageField source="image" />
                <TextField source="offer.name" />
                <TextField source="offer.price" />
                <TextField source="offer.currency_iso" />
                <UrlField source="offer.link" />
                <TextField source="merchant.name" />
            </Datagrid>
        </List>
    );
};

export default ShoppingList;