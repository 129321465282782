import React from 'react'
import { AppBar } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LogoutButton from '../LogoutButton'
const styles = theme => ({
    grid: {
        display:'grid',
        gridTemplateColumns:'1fr 1fr 80px',
        width:"100%",
        alignItems:"center",
        justifyItems:"space-between",
        marginTop:0,
        background:theme.palette.secondary.main
    },
    typo:{
        ...theme.textStyle,
        fontSize:20,
    },
    header:{
        fontSize:30,
    },
});
const AppBarCustom = ({title, classes}) =>  (
    <AppBar
    title={title}
    >
        <div className={[classes.typo,classes.grid].join(' ')}>
            <Typography className={[classes.typo,classes.header].join(' ')}>
                {window.location.hash.slice(window.location.hash.lastIndexOf('/'), window.location.hash.indexOf('?') !== -1 ? window.location.hash.indexOf('?') : window.location.hash.length )}
            </Typography>
            <Typography className={[classes.typo,classes.header].join(' ')}>
                {title} { window.env !== "prod" ? ' - ' + window.environment : '' }
            </Typography>
            <LogoutButton parentClass={classes.typo}></LogoutButton>
        </div>
    </AppBar>
    );

export default withStyles(styles)(AppBarCustom)
