import React from 'react';
import { Datagrid, TextField, EditButton, DeleteButton, ShowButton } from 'react-admin';
import CustomDateField from '../../components/CustomDateField';
import List from '../List';

const TiersList = (props) => {
    const { resource, permissions } = props;
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="headline" />
                <CustomDateField source="last_modified" label="Last modified" />
                <CustomDateField source="created_at" />
                {permissions && permissions.checkUserCanEdit(resource) ? <EditButton /> : null}
                <ShowButton />
                {permissions && permissions.checkUserCanDelete(resource) ? <DeleteButton /> : null}
            </Datagrid>
        </List>
    );
};

export default TiersList;