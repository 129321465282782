import React from 'react';
import {
    SimpleForm,
    TextInput,
} from 'react-admin';
import EditToolbar from '../EditToolbar';

const EditForm = ({ translate, ...props }) => (
    <SimpleForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<EditToolbar record={props.record} permissions={props.permissions} />}
        {...props}
    >
        <TextInput source="headline" />
    </SimpleForm>
);

export default EditForm;
