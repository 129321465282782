import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';

const PublishersEdit = props => (
    <Edit
        {...props}
    >
            <EditForm permissions={props.permissions}/>
    </Edit>
);

export default PublishersEdit;
