import React from 'react';
import {
    TextInput,
    ReferenceInput,
    Filter,
    SelectInput,
    AutocompleteInput,
    NullableBooleanInput
} from 'react-admin';

const FeedsFilters = (props) => {
    return (
        <Filter {...props}>
            <TextInput label="Feed Id" source="guid" alwaysOn/>
            <TextInput source="headline" alwaysOn/>
            <SelectInput
                alwaysOn
                label="Format"
                source="feed_type"
                choices={window.feed_type}/>
            <ReferenceInput
                alwaysOn
                source="site"
                reference="publishers"
                perPage={100}
            >
                <AutocompleteInput optionText="headline" alwaysOn />
            </ReferenceInput>
            <NullableBooleanInput source="paused" label="Paused" />
        </Filter>
    );
};

export default FeedsFilters;