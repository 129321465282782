import React, { useState } from "react";
import { BooleanInput } from "react-admin";
import FormDataContent from "../../../components/FormDataContent";
import LinkListInput from "../../../components/LinkListCard";
import { OptionsProvider } from "../../../hooks/useOptionsFiltered";
import { getSource } from "../../../tools";
import CardAppleNews from "./CardAppleNews";

export const LinkListCardNews = (props) => {
  const { record, resource, formData } = props;
  const source = props.field ? `${props.field}.` : "";
  const sourceForm = source && source.replace(/\.$/, "");
  const recordSource = (source && getSource(formData, sourceForm)) || record;
  const initialExpanded = (recordSource && recordSource.apple_news) || false;
  const [appleNewsExpanded, setAppleNewsExpanded] = useState(initialExpanded);

  return (
    <FormDataContent dataContentTitle="Apple News Config">
      <BooleanInput
        source={`apple_news`}
        label={`Apple news enable`}
        onClick={() => setAppleNewsExpanded((prevState) => !prevState)}
      />
      {appleNewsExpanded && (
        <OptionsProvider>
          <FormDataContent dataContentTitle="Default Config">
            <CardAppleNews
              source={source}
              resource={resource}
              record={record}
              formData={formData}
              isDefault={true}
              appleNewsExpanded={appleNewsExpanded}
            />
          </FormDataContent>
          <FormDataContent dataContentTitle="Territories Config">
            <LinkListInput
              expanded={true}
              addLabel={`add Territory`}
              removeLabel={`remove Territory`}
              getTitle={(item) => item.region && item.region.toUpperCase()}
              listColumns={2}
              rowGap={"20px"}
              showEmptyKeys={[]}
              inputFields={[
                <CardAppleNews
                  source={source}
                  resource={resource}
                  record={record}
                  formData={formData}
                  isDefault={false}
                  appleNewsExpanded={appleNewsExpanded}
                />,
              ]}
              {...props}
            />
          </FormDataContent>
        </OptionsProvider>
      )}
    </FormDataContent>
  );
};

LinkListCardNews.propTypes = {};

export default LinkListCardNews;
