import React from 'react';
import {
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    FunctionField
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import ListFilter from './ListFilter';
import Chip from '@material-ui/core/Chip';
import ListActions from '../../components/CreateFeedAction';
import List from '../List';

const styles = theme => ({
    chip: {
        margin: theme.spacing.unit / 2,
    },
    header: {
        backgroundColor: '#eeffef',
    },
    rowOdd: {
        backgroundColor: '#f6f6f6'
    },
    headerCell: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1em',
        backgroundColor: '#eeffef',
    }
});

const ArticlesList = ({ classes, ...props }) => (
    <List
        bulkActions={false}
        bulkActionButtons={false}
        actions={<ListActions />}
        filters={<ListFilter />}
        sort={{ field: 'publishedDate', order: 'DESC' }}
        {...props}
    >
        <Datagrid
            classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell,  }}
        >
            <TextField source="documentName" sortable={false} />
            <TextField source="id"/>
            <DateField source="publishedDate" />
            <FunctionField
                label="Article Vertical handle"
                render={(record) => (
                    !!record.articleVerticalHandle &&
                    record.articleVerticalHandle.map(tag => <Chip className={classes.chip} key={tag} label={tag} />)
                )}
            />
            <FunctionField
                label="Article Category handle"
                render={(record) => (
                    !!record.articleCategoryHandle &&
                    record.articleCategoryHandle.map(tag => <Chip className={classes.chip} key={tag} label={tag} />)
                )}
            />
            <FunctionField
                label="Article Tag handle"
                render={(record) => (
                    !!record.articleTagHandle &&
                    record.articleTagHandle.map(tag => <Chip className={classes.chip} key={tag} label={tag} />)
                )}
            />
            <FunctionField
                label="Article control tag"
                render={(record) => (
                    !!record.articleControlTag &&
                    record.articleControlTag.map(tag => <Chip className={classes.chip} key={tag} label={tag} />)
                )}
            />
            <ReferenceField source="site" sortable={false} reference="publishers" label="Site">
                <TextField source="headline" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export default withStyles(styles)(ArticlesList);