import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import {BooleanInput} from "react-admin";
import messages from "../messages";

const CustomBooleanInput = ({ source, tooltipMessage }) =>
    <span style={{display: 'flex'}}>
        {tooltipMessage &&
            <Tooltip
                title={<span>
                    <p style={{margin: '0'}}>{messages.feeds[tooltipMessage]}</p>
                    </span>}
                arrow
            >
                <InfoIcon style={{color: 'grey', width: '15px', height: 'auto', marginRight: '10px'}}></InfoIcon>
            </Tooltip>
        }
        <BooleanInput source={source}/>
    </span>

export default CustomBooleanInput;
