import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import Tooltip from '@material-ui/core/Tooltip';
import { removeSession } from "../authProvider";
import Auth from "../Auth";

const styles = theme => ({
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
});


class LogoutButton extends Component {
    auth = new Auth();

    handleLogout = () => {
        removeSession();
        this.auth.logout();
    };

    render() {
        const { label, Icon, classes, parentClass } = this.props;
        return (
            <Button
                onClick={this.handleLogout}
                size="small"
            >
                <Tooltip title={label}>
                    <Icon className={[classes.extendedIcon,parentClass].join(' ')} />
                </Tooltip>
            </Button>
        )
    }
}

LogoutButton.defaultProps = {
    label: 'Logout',
    Icon: ExitIcon
};

export default  withStyles(styles)(LogoutButton);