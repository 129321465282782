import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Cookies from "js-cookie";
import React from 'react';
import {
    AutocompleteInput,
    FormDataConsumer,
    SaveButton, SelectInput,
    SimpleForm,
    TextInput,
    Toolbar
} from 'react-admin';
import { required } from "../validate";

const styles = {
    full: {
        width:"100%",
        margin: "20px 0"
    },
    paper: {
        boxSizing: 'border-box',
        padding: '20px',
    },
}

const ClearToolbar = props => (
    <Toolbar {...props}>
        <SaveButton
            redirect={false}
            submitOnEnter
            label={"Clear cache"}
        />
    </Toolbar>
);

const EditForm = ({ translate, classes, ...props }) => (
    <SimpleForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<ClearToolbar record={props.record} permissions={props.permissions} />}
        {...props}
    >
        <h2>Clear FTE Cache</h2>
        <SelectInput
            source="clear_cache_mode"
            choices={[
                { id: 'xkey', name: 'Xkeys' },
                { id: 'surrogateKey', name: 'Surrogate Keys' },
                { id: 'uri', name: 'Uri' },
                { id: 'ampUri', name: 'AMP Uri' },
                { id: 'keys', name: 'Keys (Surrogate + Xkeys)' },
            ]}
            defaultValue="xkey"
            validate={required()}
        />
        <TextInput
            source="clear_cache_value"
            fullWidth={true}
            validate={required()}
        />
        <FormDataConsumer>
            {({ formData }) =>
                formData && ( formData.clear_cache_mode === 'xkey' || formData.clear_cache_mode === 'surrogateKey' || formData.clear_cache_mode === 'keys' ) &&
                        <AutocompleteInput
                            source="clear_cache_host"
                            choices={window.sitesHosts}
                            className={classes.full}
                            optionText={"host"}
                            optionValue={"host"}
                            validate={required()}
                        />
            }

        </FormDataConsumer>
        {Cookies.get('jobId') &&
        <div className={classes.full}>
            <Paper className={classes.paper}>
                <Typography ><b>Last purge action:</b></Typography>
                <a  href={`http://master.cachepurger.vanilla.van-prod.futureplc.engineering/job/status/${Cookies.get('jobId')}`} >
                    <Typography >{`http://master.cachepurger.vanilla.van-prod.futureplc.engineering/job/status/${Cookies.get('jobId')}`}</Typography>
                </a>
                <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.parse(Cookies.get('purgeData')) }</pre>
            </Paper>
        </div>
        }

    </SimpleForm>

);

export default withStyles(styles)(EditForm);
