import AddIcon from "@material-ui/icons/Add";
import React, { Component, Fragment } from "react";
import {
    FormDataConsumer,
    Labeled,
    NumberInput,
    SelectInput,
    TextInput,
} from "react-admin";
import { Field } from "redux-form";
import { getSource, orderByKey } from "../../tools";
import Grid from "../Grid";
import IconButton from "../IconButton";
import InputFile from "../InputFile";
import LinkListCard from "./Card";
export const negativeNumber = (value) =>
  value && value < 0 ? "This number must be >= 0" : undefined;
export const number = (value) =>
  value && !Number.isInteger(value) ? "Must be a number" : undefined;
export const minValue = (min) => (value) =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValue0 = minValue(0);
class LinkListInput extends Component {

  render() {
    const { source, label, additionnalFields,listColumns = 1,rowGap = "0", ...rest } = this.props;
    const { fields, addLabel, hideAddButton } = rest;
    return (
      <FormDataConsumer>
        {({ formData }) => (
          <Fragment>
            <Labeled label={label} />
            <div style={{ marginBottom: "8px" }}>
              <Grid columns={listColumns} rowGap={rowGap} width={"100%"}>
                {fields.length > 0 &&
                  fields
                    .map((itemSource, index) => (
                      <LinkListCard
                        key={`link-list-${itemSource}`}
                        index={index}
                        source={itemSource}
                        {...rest}
                      >
                        {additionnalFields &&
                          additionnalFields.map((component) => component)}
                      </LinkListCard>
                    ))
                    .sort((a, b) => {
                      const sourceA = getSource(formData, a.props.source);
                      const sourceB = getSource(formData, b.props.source);
                      return orderByKey(sourceA, sourceB, "position");
                    })}
              </Grid>
            </div>
            {hideAddButton === false && (
              <IconButton
                color="primary"
                icon={(classes) => <AddIcon className={classes.leftIcon} />}
                onClick={() => fields.push({})}
              >
                {addLabel}
              </IconButton>
            )}
          </Fragment>
        )}
      </FormDataConsumer>
    );
  }
}

LinkListInput.defaultProps = {
  label: "Link list",
  getTitle: (item) => (item.title ? item.title : ""),
  showEmptyKeys: ["title", "position", "url"],
  addLabel: "Add link",
  removeLabel: "Remove link",
  initiallyExpanded: (record) => !record.title && !record.url,
  extendedTitle: null,
  hideAddButton: false,
  inputFields: [
    <TextInput source="title" label="Title" resettable />,
    <NumberInput
      source="position"
      label="Position"
      validate={[number, minValue0]}
      warn={negativeNumber}
    />,
    <TextInput source="url" label="URL" resettable />,
    <TextInput source="description" label="Description" resettable />,
    <SelectInput
      source="target"
      label="Target"
      defaultValue={"_self"}
      choices={[
        { id: "_blank", name: "Blank" },
        { id: "_self", name: "Self (default)" },
        { id: "_parent", name: "Parent" },
        { id: "_top", name: "Top" },
        { id: "framename", name: "Framename" },
      ]}
      resettable
    />,
    <Field multiple={false} name="icon" label="Icon" component={InputFile} />,
  ],
};

export default LinkListInput;
