import React from 'react';
import {
    Filter,
    translate as trans,
} from 'react-admin';
import { renderFilters } from '../Feeds/EditForm';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    form: {
        display: 'block',
        width: '100%',
        marginTop: theme.spacing.unit * 4,
    },
});

const ListFilter = ({ classes, translate, ...props }) => (
    <Filter {...props} classes={{ form: classes.form }}>
        {renderFilters()}
    </Filter>
);

export default withStyles(styles)(trans(ListFilter));
