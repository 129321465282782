import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import React, { useEffect, useState } from 'react';
import {
  BooleanInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import { FieldArray } from 'redux-form';
import FieldArrayCardFormComponent from "../../components/FieldArrayCardFormComponent";

const CardOneSignal = (props) => {

  const [oneSignalExpanded, setOneSignalExpanded] = useState((props.record && props.record.one_signal )|| false );
  const [categories, setCategories] = useState([]);
  const [didFetch, setDidFetch] = useState(false)

  useEffect(() => {
      if(!didFetch) {
          fetch('api/publishers/categories/proof_'+props.record.id)
          .then((response) => response.json())
          .then(
              data => {
                  const choices = (data.length > 0 && data.map(value => ({ id: value, name: value.charAt(0).toUpperCase() + value.slice(1) }))) || []
                  choices.sort()
                  setCategories(choices)
                  setDidFetch(true);
              }
          )
      }
  },[]);

  return(<div>
          <BooleanInput
              source="one_signal"
              onClick={() => setOneSignalExpanded((prevValue) => !prevValue)}
          />
          <Card>
              <Collapse in={oneSignalExpanded} timeout="auto" >
                  <CardContent>
                      <TextInput
                          source="one_signal_key"
                          helperText="Used to connect to the OneSignal Api"
                          style={{ display: 'block' }}
                      />
                      <TextInput
                          source="one_signal_id"
                          helperText="Used to connect to the OneSignal Api"
                          style={{ display: 'block' }}
                      />
                      <TextInput
                          source="one_signal_custom_data_url"
                          helperText="optional url used for custom data"
                          style={{ display: 'block' }}
                      />
                      <FieldArray
                          myLabel="One Signal Segment"
                          name="one_signal_segments"
                          helperText="Leave it empty to get all Subscribed Users"
                          component={FieldArrayCardFormComponent}
                          arrayForm={[
                              <TextInput source={`name`} label="Segment Name" helperText="" />,
                          ]}
                      />
                      <SelectInput
                          source="one_signal_category"
                          choices={categories}
                          optionText='name'
                          optionValue='id'
                          emptyValue=''
                          resettable={true}
                          />
                      <br/>
                  </CardContent>
              </Collapse>
          </Card>
      </div>
  )
};

CardOneSignal.propTypes = {}

export default CardOneSignal