import React from 'react';
import { translate as trans } from 'react-admin';
import FiltersInput from '../../components/FiltersInput';
import messages from '../../messages';

const Form = ({ children, translate, ...props }) => React.Children.map(children, Child => {
    if (!(Child && (Child.props.source || Child.props.name))) {
        return Child;
    }
    const childProps = {
        label: `feeds.${Child.props.source}`,
        style: {
            width: '100%'
        },
        ...props
    };
    if (messages.feeds[`${Child.props.source}_helper`]) {
        childProps.helperText = translate(`feeds.${Child.props.source}_helper`, Child.props.transVariables);
    }

    return (
        <FiltersInput key={Child.props.source}>
            {React.cloneElement(Child, childProps)}
        </FiltersInput>
    );
});

export default trans(Form);