import Cookies from 'js-cookie';
import jsonServerProvider from 'ra-data-json-server';
import { fetchUtils } from 'react-admin';
import { checkUserComesFromCMS } from './authProvider';

const currentBranch = window.currentBranch.toLowerCase();

const makeBranchedUrl = url => `${url + (url.indexOf('?') >= 0 ? '&' : '?')}branch=${currentBranch}&cleanDataForFront=true`;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const newUrl = makeBranchedUrl(url);
    // add your own headers here
    options.headers.set('X-User-Name', localStorage.getItem('user'));

    if (checkUserComesFromCMS()){
        options.headers.set('X-From-CMS', true);
    }

    if (localStorage.getItem('companies')) {
        options.headers.set('X-Companies', localStorage.getItem('companies'));
    }

    if (localStorage.getItem('role')) {
        options.headers.set('X-Role', localStorage.getItem('role'));
    }

    options.headers.set('X-User-Name', localStorage.getItem('user'));

    if (localStorage.getItem('expiresIn')) {
        options.headers.set('X-ExpiresIn', localStorage.getItem('expiresIn'));
    }

    if (Cookies.get('token')) {
        options.headers.set('Authorization', `Bearer ${Cookies.get('token')}`);
    }

    return fetchUtils
        .fetchJson(window.currentBranch ? newUrl : url, options)
            .then(response => response)
            .catch(err => {
                if (401 === err.status) {
                    window.location.href = '#/login'
                }

                const message = err.body &&
                    err.body.error &&
                    err.body.error.exception &&
                    err.body.error.exception.length ?
                    err.body.error.exception[0].message : err.message;
                throw new Error(message);
            });
};

const restClient = jsonServerProvider('/api', httpClient);

export default restClient;

export {
    currentBranch, httpClient as fetch, makeBranchedUrl
};
