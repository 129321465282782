import React from 'react';
import {
    SelectField, TextField, SimpleShowLayout,
} from 'react-admin';
import Show from '../Show';

const CorporationsShow = props => (
    <Show title="Tier" {...props}>
        <SimpleShowLayout>
            <TextField source="guid" />
            <SelectField
                source="tiers"
                choices={[
                    { id: 'standard', name: 'Standard' },
                    { id: 'sremium', name: 'Premium' },
                ]}
            />
        </SimpleShowLayout>
    </Show>
);

export default CorporationsShow;
