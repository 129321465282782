import Icon from '@material-ui/icons/ShoppingCart';
import List from './List';
import Show from './Show';

const name = 'shopping';

export {
    List,
    Show,
    Icon,
    name,
};
