// in src/App.js
import React from 'react';
import { Admin, Resource, resolveBrowserLocale } from 'react-admin';
import { createMuiTheme } from '@material-ui/core/styles';
import englishMessages from 'ra-language-english';
import resources from './resources';
import restClient from './restClient';
import authProvider from './authProvider';
import messages from './messages';
import { groups } from './Entities/Feeds/reducers';
import LoginPage from './LoginPage';
import MainLayout from './components/Layout';

const i18n = {
    en: { ...englishMessages, ...messages },
};

// Always return "en"
const i18nProvider = () => i18n['en'];

export const theme = createMuiTheme({
    palette: {
        type: 'light',
        secondary:{ main:window.environment !== "prod" ? '#851be6' : '#f50057' },
    },
    textStyle: {
        color: 'white'
    }
});

const capitalize = (s) => {
    return typeof s !== 'string' ? '' : s.charAt(0).toUpperCase() + s.slice(1)
};

export default () => (
    <Admin
        locale={resolveBrowserLocale()}
        i18nProvider={i18nProvider}
        dataProvider={restClient}
        authProvider={authProvider}
        theme={theme}
        customReducers={{ filteredGroups: groups }}
        loginPage={LoginPage}
        position="static"
        appLayout={MainLayout}
        title={"Airedale"}
    >
        {permissions => resources.map(resource => (
            <Resource
                key={resource.name}
                name={resource.name}
                list={permissions && permissions.checkUserCanRead(resource.name) ? resource.List : null}
                edit={permissions && permissions.checkUserCanEdit(resource.name) ? resource.Edit : null}
                create={permissions && permissions.checkUserCanCreate(resource.name) ? resource.Create : null}
                show={resource.Show}
                icon={resource.Icon}
                options={{ label: capitalize(resource.label ? resource.label : resource.name) }}
            />
        ))}
    </Admin>
);