import React, {Fragment} from 'react';
import {SimpleForm, TextInput, SelectInput} from 'react-admin';
import { Field } from 'redux-form';
import EditToolbar from '../EditToolbar';
import { FormDataConsumer } from 'react-admin';
import FeedTemplateInput from '../../components/FeedTemplateInput';

import ContentTypeInput from "../../components/ContentTypeInput";
import AceEditorInput from "../../components/AceEditorInput";
import {required} from "../validate";
import validateCustomTemplate from "./validateCustomTemplate";

const EditForm = ({ translate, ...props }) => (
    <SimpleForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<EditToolbar record={props.record} permissions={props.permissions} />}
        validate={validateCustomTemplate}
        {...props}
    >
        <TextInput source="headline"/>
        <SelectInput
            source="feed_document_type"
            choices={[
                { id: 'article', name: 'article' },
                { id: 'productcampaign', name: 'deals' },
                { id: 'videos', name: 'videos' },
                { id: 'shopping', name: 'shopping' },
                { id: 'forums', name: 'forums' },
            ]}
            defaultValue="article"
            validate={required()}
        />
        <FormDataConsumer>
            {({ formData }) =>
                formData &&
                <Fragment>
                    <FeedTemplateInput source="feed_template" data={formData}/>
                    <Field labl="Item template" name="item_template" source="item_template" component={AceEditorInput} data={formData}/>
                </Fragment>
            }
        </FormDataConsumer>
        <TextInput source="item_separator" />
        <TextInput source="item_formative" label={'Macro delimiter'}/>
        <Field name="content_type" component={ContentTypeInput} />
    </SimpleForm>
);

export default EditForm;
