import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CardActions, RefreshButton } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import IconButton from './IconButton';

const styles = theme => ({
    root: {
        position: 'absolute',
        top: theme.spacing.unit,
        right: theme.spacing.unit,
    },
});

class CreateFeedAction extends Component {
    shouldComponentUpdate(prevProps) {
        const { filterValues } = this.props;
        return filterValues !== prevProps.filterValues;
    }

    render() {
        const {
            classes,
            displayedFilters,
            filters,
            filterValues,
            resource,
            showFilter
        } = this.props;

        return (
            <CardActions classes={{ cardActions: classes.root }}>
                {filterValues && Object.keys(filterValues).length > 0 && (
                    <IconButton
                        component={Link}
                        variant="contained"
                        color="primary"
                        size="small"
                        to={{
                            pathname: '/feeds/create',
                            state: { record: { ...filterValues, feed_document_type: resource === 'videos' ? 'videos' : undefined } },
                        }}
                        icon={iconClasses => <AddIcon className={iconClasses.leftIcon} />}
                    >
                        Create a feed from selected filters
                    </IconButton>
                )}
                {filters && React.cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                }) }
                <RefreshButton />
            </CardActions>
        );
    }
}

export default withStyles(styles)(CreateFeedAction);
