import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const styles = () => ({
    full: {
        maxWidth: '70vw',
        margin: "20px 0",
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word'
    },
    paper: {
        boxSizing: 'border-box',
        padding: '20px',
        overflowX: 'auto',
        marginBottom: '30px'
    },
    table: {
        marginTop: '10px'
    },
    tableCell : {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word'
    }
});

class Debug extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };

        this.getListedUrlParameters = this.getListedUrlParameters.bind(this);
    }

    getListedUrlParameters = (url) => {
        const { classes } = this.props;
        if(!url) return [<Typography>No parameters</Typography>];
    
        let result = {}
        let paramList = []

        let urlParts = url.split("?")
        let endpoint = urlParts[0]
        let parameters = urlParts[1]

        parameters.split("&").forEach(function(part) {
          var item = part.split("=");
          result[item[0]] = decodeURIComponent(item[1]);
        });

        paramList = Object.keys(result).map(key =>
            <TableRow>
                <TableCell  className={classes.tableCell} align="right">
                <Typography><b>{key}</b> : {result[key]}</Typography>
                </TableCell>
            </TableRow>
        );

        paramList.push(<TableRow>
                <TableCell className={classes.tableCell} align="right">
                    <Typography><b>endpoint</b> : {endpoint.replace(/(http:\/\/)(.*):(.*)@/, '$1$2:***@')}</Typography>
                </TableCell>
            </TableRow>)

        return paramList
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.full}>
                <Typography>QUERIES</Typography>
                    {this.props && !this.props.queries &&
                        <Typography><b>No available query, try to SAVE once</b></Typography>
                    }
                    {this.props && this.props.queries && this.props.queries.map((query => (
                        <Paper className={classes.paper}>
                        <React.Fragment>
                        <div>
                            <a  href={query.replace(/(http:\/\/)(.*):(.*)@/, '$1$2:***@')} >
                                <Typography >{query.replace(/(http:\/\/)(.*):(.*)@/, '$1$2:***@')}</Typography>
                            </a>
                        </div>
                        <div>
                            <Table aria-label="custom pagination table" className={classes.table}>
                                <TableBody>
                                    {this.getListedUrlParameters(query).map(((row) => (
                                        row
                                    )))}
                                </TableBody>
                            </Table>
                        </div>
                        </React.Fragment>
                        </Paper>
                    )))}
            </div>
        );
    }
}

export default  withStyles(styles)(Debug);
