import React from 'react';
import {
    SelectField, TextField, ImageField, SimpleShowLayout, RichTextField,
} from 'react-admin';
import Show from '../Show';

const VideosShow = props => (
    <Show title="Tier" {...props}>
        <SimpleShowLayout>
            <TextField
                source="guid"
            />
            <TextField
                source="headline"
            />
            <TextField
                source="source"
            />
            <SelectField
                source="source_type"
                choices={[
                    { id: 'internal', name: 'Internal' },
                    { id: 'external', name: 'External' }
                ]}
            />
            <ImageField source="thumbnail" />
            <TextField source="category" />
            <TextField source="domain_url" />
            <RichTextField source="logo_svg" />
        </SimpleShowLayout>
    </Show>
);

export default VideosShow;
