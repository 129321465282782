import React from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import { Labeled } from "react-admin";
import { withStyles } from '@material-ui/core/styles';

const styles = {
    deepRta : {
        "div.rta__textarea:hover": {borderBottom: "3px solid rgba(0, 0, 0, 0.42)"},
        "& ~ div.rta__autocomplete li:hover":{backgroundColor:"rgba(0, 0, 0, 0.08)"},
    }
};

class TextareaAutoComplete extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            value: props.input.value,
        }
    }

    componentDidMount(){
        this.resizeTextarea()
    }

    handleChange = (event) => {
        const {input} = this.props;
        input.onChange(event.target.value);
        this.resizeTextarea()

        this.setState({
            value: event.target.value,
        });
    };

    resizeTextarea () {
        let textArea = this.textarea
        textArea.style.height = 'auto';
        textArea.style.height = textArea.scrollHeight+'px';
    }

    RenderItem = ({ entity: { id, text } }) => {
        return text ? <div>{`${id}`}<span style={{color: 'grey'}}> - {text}</span></div> : <div>{`${id}`}</div>;
    };

    render() {
        const Loading = ({ data }) => <div>Loading</div>;
        const {label, classes, suggests} = this.props;
        return (
            <Labeled label={label}>
                <ReactTextareaAutocomplete
                    className={classes.deepRta}
                    onChange={this.handleChange}
                    loadingComponent={Loading}
                    rows={1}
                    cols={200}
                    value={this.state.value}
                    style={{
                        width: "100%",
                        fontSize: "16px",
                        padding: "2px 0 3px",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                        resize: "none",
                        outline: "none"
                    }}
                    ref={rta => {
                        this.rta = rta;
                    }}
                    innerRef={textarea => {
                        this.textarea = textarea;
                    }}
                    listStyle={{
                        width: "100%",
                        listStyleType: "none",
                        borderRadius: "4px",
                        boxShadow: "0 8px 10px 1px rgba(0, 0, 0, 0.14)",
                        fontFamily: 'Arial',
                        padding: "8px 0",
                        opacity: 1,
                        position: "absolute",
                        backgroundColor: "white",
                        zIndex: "2147483647"
                    }}
                    itemStyle={{
                        width: "auto",
                        cursor: "pointer",
                        padding: "12px 16px",
                    }}
                    minChar={0}
                    trigger={{
                        ",": {
                            dataProvider: value => {
                                const involvedList = [];
                                const suggestsList = suggests;
                                suggestsList.map((item) => {
                                    if(item.id.indexOf(value) !== -1) {
                                        involvedList.push(item)
                                    }
                                });
                                return involvedList.slice(0, 7);
                            },
                            component: this.RenderItem,
                            output: (item, trigger) => ',' + item.id
                        }
                    }}
                />
            </Labeled>
        );
    }
}

export default withStyles(styles)(TextareaAutoComplete);