import React from 'react';
import {
    ReferenceArrayField, TextField, SingleFieldList, ChipField, SimpleShowLayout,
} from 'react-admin';
import Show from '../Show';

const ForumsShow = props => (
    <Show title="Tier" {...props}>
        <SimpleShowLayout>
            <TextField
                source="guid"
            />
            <TextField
                source="headline"
            />
            <TextField
                source="source"
            />
            <ReferenceArrayField
                label="feeds.site"
                reference="publishers"
                source="site"
            >
                <SingleFieldList>
                    <ChipField source="headline" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="thread_id" />
            <TextField source="tags" />
        </SimpleShowLayout>
    </Show>
);

export default ForumsShow;
