import React, { Component } from 'react';
import { Layout } from 'react-admin';
import { theme } from '../../Admin'
import { withStyles } from '@material-ui/core/styles';
import AppBar from './AppBar';

const styles = () => ({
    grid: {
        display: 'flex',
        justifyContent:"space-between"
    },
})
class MainLayout extends Component {
    render() {
        const { children, classes, title } = this.props;
        return (
            <Layout
                className={classes.grid}
                theme={theme}
                title={title}
                appBar={AppBar}
                >
                {children}
            </Layout>
        )
    }
}

export default withStyles(styles)(MainLayout);
