import React from 'react';
import Create from '../Create';
import EditForm from './EditForm';

const CorporationsCreate = props => (
    <Create {...props}>
        <EditForm permissions={props.permissions} />
    </Create>
);

export default CorporationsCreate;
