import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetch } from '../restClient';
import { ReactMUIDatatable } from "react-material-ui-datatable";
import Modale from './Modale';

class ChangelogField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
        };
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        fetch('/api/' + this.props.resource + '_log/' + this.props.guid)
            .then(response => response.json)
            .then((data) => {
                this.setState({
                    isLoading: false,
                    data: data,
                });
            })
            .catch((error) => {
                this.setState({ isLoading: false });
            });
    }

    render() {
        const { data, isLoading } = this.state;

        if (isLoading) {
            return <CircularProgress size={40} thickness={2} />;
        }

        const customCell = ({ value, column, row }) => {
            if (column.name === "modified_at") {
                return new Date(value * 1000).toUTCString();
            }
            if (column.name === "old") {
                return <a target={'_blank'} rel="noopener noreferrer" href={'/api/' + this.props.resource + '_archived/' + value}>{value}</a>;
            }
            if (column.name === "new") {
                return <a target={'_blank'} rel="noopener noreferrer" href={'/api/' + this.props.resource + '_archived/' + value}>{value}</a>;
            }
            if (column.name === "action") {
                return <Modale display_restore_button={row.version !== data.last_version}  item={row} record={this.props.record} resource={this.props.resource} ></Modale>;
            }

            return value;
        };

        const columns = [
            { label: "Version", name: "version" },
            { label: "Modified by", name: "modified_by" },
            { label: "Modified at", name: "modified_at" },
            { label: "From", name: "old" },
            { label: "To", name: "new" },
            { label: "Action", name: "action" }
        ];

        return (
            <div>
                {data && data.changes && <ReactMUIDatatable
                                            title={"CHANGELOG"}
                                            data={data.changes}
                                            columns={columns}
                                            customCell={customCell}
                                            perPage={10}
                                            perPageOption={[10, 20, 50]}
                                            selectable={false} />}
            </div>
        );
    }
}

export default ChangelogField;

