import React, {Component} from 'react';
import AceEditor from 'react-ace';
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-spellcheck";
import "ace-builds/src-min-noconflict/snippets/html";
import { withStyles } from '@material-ui/core/styles';

const styles = {
    aceList : {
        "& .ace_editor.ace_autocomplete": {
            width:"70%",
            padding:"5px 0",
            overflowY:"hidden",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            border: "0",
            "& .ace_line": {cursor: "pointer"},
            "& .ace_marker-layer": {
                "& .ace_active-line":{backgroundColor: "white"},
                "& .ace_line-hover":{backgroundColor: "#e8e8e8", border:"0", cursor: "pointer"},
            }
        },
    }
};

class AceEditorInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.input.value,
        };
    }

    componentDidUpdate(prevProps) {
        const {data} = this.props;
        if (prevProps.data.feed_document_type !== data.feed_document_type) {
            this.editorCompletersByFeedDocumentType();
        }
    }

    componentDidMount() {
        const {input: {value}, classes} = this.props;
        document.body.classList.add(classes.aceList);
        this.setState({value});

        this.editorCompletersByFeedDocumentType();
    }

    componentWillUnmount() {
        const {classes} = this.props;
        document.body.classList.remove(classes.aceList);
    }

    editorCompletersByFeedDocumentType() {
        const {data} = this.props;
        const completers = this.refs.aceEditor.editor.completers;
        const len = completers.length;
        completers.splice(0, len);
        completers.push({
            getCompletions: function(editor, session, pos, prefix, callback) {
                const completions = [];
                const feedDocumentType = data.feed_document_type === 'curatedlist' ? 'article' : data.feed_document_type;
                const suggestsList = window.feedTemplatePlacehodersChoices.concat(window[feedDocumentType + "PlacehodersChoices"]);
                let customPlaceholdersList = data.custom_placeholders;
                suggestsList.forEach(function(item) {
                    completions.push({
                        value: '%' + item.id + '%',
                        meta: item.text,
                    });
                });

                if (typeof customPlaceholdersList !== 'undefined') {
                    customPlaceholdersList.forEach(function(item) {
                        completions.push({
                            value: '%' + item.key + '%',
                            meta: item.value,
                        });
                    });
                }

                callback(null, completions);
            }
        })
    }

    handleValueChange(value) {
        this.setState({value});
        this.props.input.onChange(value);
    };

    render() {
        const { labl, row } = this.props;
        const height = row ? row : "500px";
        return (
            <div>
                <h5 style={{margin: "10px 0", color: 'grey', 'fontFamily': 'Arial', fontWeight: 'normal', fontSize: '12px'}}>{labl}</h5>
                <AceEditor
                    mode="html"
                    style={{
                        padding: 5,
                        borderBottom: "1px solid black",
                    }}
                    ref="aceEditor"
                    theme="monokai"
                    name={this.props.source}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={this.state.value}
                    minLines={1}
                    maxLines={50}
                    onChange={value=>this.handleValueChange(value)}
                    height={height}
                    width={"100%"}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        useWorker: false,
                        tabSize: 2,
                    }} />
            </div>
        );
    }
}

export default withStyles(styles)(AceEditorInput);




