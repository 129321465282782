const orderFields = [
    { id: 'publishedDate', name: 'Publish date' },
    { id: 'modifiedDate', name: 'Updated date' },
    { id: 'contentModifiedDate', name: 'Modified Content Date' },
    { id: 'createdDate', name: 'Created date' },
    { id: 'max(scheduleUpdateDate, contentModifiedDate)', name: 'ScheduleUpdateDate and contentModifiedDate' },
    { id: 'max(scheduleUpdateDate, publishedDate)', name: 'ScheduleUpdateDate and PublishedDate' },
    { id: 'max(scheduleUpdateDate, modifiedDate)', name: 'ScheduleUpdateDate and ModifiedDate' },
    { id: 'max(scheduleUpdateDate, createdDate)', name: 'ScheduleUpdateDate and CreatedDate' },
    { id: 'articleLiveCoverageStart ', name: 'Article Live Coverage Start '},
    { id: 'articleLiveCoverageEnd ', name: 'Article Live Coverage End '},
    { id: 'eventGroupStartDate ', name: 'Event Group Start Date '},
    { id: 'eventGroupEndDate ', name: 'Event Group End Date '},
    { id: 'personGroupStartDate ', name: 'Person Group Start Date '},
    { id: 'personGroupEndDate ', name: 'Person Group End Date '},
    { id: 'metrics_all_region_engagement_rank', name: 'Metrics all region engagement rank'},
    { id: 'metrics_all_region_popularity_rank', name: 'Metrics all region popularity rank'},
    { id: 'metrics_all_region_trending_rank', name: 'Metrics all region trending rank'},
    { id: 'metrics_AE_region_engagement_rank', name: 'Metrics AE region engagement rank'},
    { id: 'metrics_AU_region_engagement_rank', name: 'Metrics AU region engagement rank'},
    { id: 'metrics_DE_region_engagement_rank', name: 'Metrics DE region engagement rank'},
    { id: 'metrics_DK_region_engagement_rank', name: 'Metrics DK region engagement rank'},
    { id: 'metrics_ES_region_engagement_rank', name: 'Metrics ES region engagement rank'},
    { id: 'metrics_ES_US_region_engagement_rank', name: 'Metrics ES_US region engagement_rank'},
    { id: 'metrics_FI_region_engagement_rank', name: 'Metrics FI region engagement rank'},
    { id: 'metrics_FR_region_engagement_rank', name: 'Metrics FR region engagement rank'},
    { id: 'metrics_GB_region_engagement_rank', name: 'Metrics GB region engagement rank'},
    { id: 'metrics_IN_region_engagement_rank', name: 'Metrics IN region engagement rank'},
    { id: 'metrics_IT_region_engagement_rank', name: 'Metrics IT region engagement rank'},
    { id: 'metrics_NL_region_engagement_rank', name: 'Metrics NL region engagement rank'},
    { id: 'metrics_NO_region_engagement_rank', name: 'Metrics NO region engagement rank'},
    { id: 'metrics_NZ_region_engagement_rank', name: 'Metrics NZ region engagement rank'},
    { id: 'metrics_SE_region_engagement_rank', name: 'Metrics SE region engagement rank'},
    { id: 'metrics_SG_region_engagement_rank', name: 'Metrics SG region engagement rank'},
    { id: 'metrics_US_region_engagement_rank', name: 'Metrics US region engagement rank'},
    { id: 'metrics_ZA_region_engagement_rank', name: 'Metrics ZA region engagement rank'},
    { id: 'metrics_AE_region_popularity_rank', name: 'Metrics AE region popularity rank'},
    { id: 'metrics_AU_region_popularity_rank', name: 'Metrics AU region popularity rank'},
    { id: 'metrics_DE_region_popularity_rank', name: 'Metrics DE region popularity rank'},
    { id: 'metrics_DK_region_popularity_rank', name: 'Metrics DK region popularity rank'},
    { id: 'metrics_ES_region_popularity_rank', name: 'Metrics ES region popularity rank'},
    { id: 'metrics_ES_US_region_popularity_rank', name: 'Metrics ES_US region popularity_rank'},
    { id: 'metrics_FI_region_popularity_rank', name: 'Metrics FI region popularity rank'},
    { id: 'metrics_FR_region_popularity_rank', name: 'Metrics FR region popularity rank'},
    { id: 'metrics_GB_region_popularity_rank', name: 'Metrics GB region popularity rank'},
    { id: 'metrics_IN_region_popularity_rank', name: 'Metrics IN region popularity rank'},
    { id: 'metrics_IT_region_popularity_rank', name: 'Metrics IT region popularity rank'},
    { id: 'metrics_NL_region_popularity_rank', name: 'Metrics NL region popularity rank'},
    { id: 'metrics_NO_region_popularity_rank', name: 'Metrics NO region popularity rank'},
    { id: 'metrics_NZ_region_popularity_rank', name: 'Metrics NZ region popularity rank'},
    { id: 'metrics_SE_region_popularity_rank', name: 'Metrics SE region popularity rank'},
    { id: 'metrics_SG_region_popularity_rank', name: 'Metrics SG region popularity rank'},
    { id: 'metrics_US_region_popularity_rank', name: 'Metrics US region popularity rank'},
    { id: 'metrics_ZA_region_popularity_rank', name: 'Metrics ZA region popularity rank'},
    { id: 'metrics_AE_region_trending_rank', name: 'Metrics AE region trending rank'},
    { id: 'metrics_AU_region_trending_rank', name: 'Metrics AU region trending rank'},
    { id: 'metrics_DE_region_trending_rank', name: 'Metrics DE region trending rank'},
    { id: 'metrics_DK_region_trending_rank', name: 'Metrics DK region trending rank'},
    { id: 'metrics_ES_region_trending_rank', name: 'Metrics ES region trending rank'},
    { id: 'metrics_ES_US_region_trending_rank', name: 'Metrics ES_US region trending rank'},
    { id: 'metrics_FI_region_trending_rank', name: 'Metrics FI region trending rank'},
    { id: 'metrics_FR_region_trending_rank', name: 'Metrics FR region trending rank'},
    { id: 'metrics_GB_region_trending_rank', name: 'Metrics GB region trending rank'},
    { id: 'metrics_IN_region_trending_rank', name: 'Metrics IN region trending rank'},
    { id: 'metrics_IT_region_trending_rank', name: 'Metrics IT region trending rank'},
    { id: 'metrics_NL_region_trending_rank', name: 'Metrics NL region trending rank'},
    { id: 'metrics_NO_region_trending_rank', name: 'Metrics NO region trending rank'},
    { id: 'metrics_NZ_region_trending_rank', name: 'Metrics NZ region trending rank'},
    { id: 'metrics_SE_region_trending_rank', name: 'Metrics SE region trending rank'},
    { id: 'metrics_SG_region_trending_rank', name: 'Metrics SG region trending rank'},
    { id: 'metrics_US_region_trending_rank', name: 'Metrics US region trending rank'},
    { id: 'metrics_ZA_region_trending_rank', name: 'Metrics ZA region trending rank'},
    { id: 'metrics_avgTimeSpent', name: 'Metrics avgTimeSpent'},
    { id: 'metrics_timeSpent', name: 'Metrics timeSpent'},
    { id: 'metrics_futureScore', name: 'Metrics futureScore'},
    { id: 'metrics_transactionScore', name: 'Metrics transactionScore'},
    { id: 'metrics_pageViewScore', name: 'Metrics pageViewScore'}
];

export default orderFields;