import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  Button,
} from "@material-ui/core";

const styles = (theme) => ({
    boxWrapper: {
      position: "absolute",
      padding: 15,
      border: "1px solid #333",
      backgroundColor: "#FFF",
      zIndex: 1,
      marginLeft: -200,
      width: 350,
    },
    input: {
      marginTop: 5,
      marginBottom: 15,
    },
    box: {
      display: "block",
      '& > *': {
        width: "100%",
      },
    },
    button: {
      width: "50%",
      backgroundColor: "#4051B6",
      display: "block",
      margin: "auto",
      "&:hover": {
        backgroundColor: "#4051B6",
        opacity: 0.8,
      },
      "&:disabled": {
        backgroundColor: "grey",
        opacity: 0.8,
      },
      color: "#fff",
    },
  });

class CloneFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guidTo: `feed_${(new Date()).getTime()}` ,
            newGuidValue: "",
            showInput:this.props.showInput || false,
        };
    }

    render() {
        const {guidTo, showInput} = this.state;
        const {record,classes,cbHandleClone} = this.props;

        return (
            <Fragment>
                {showInput && <div id={`${record.id}-clone-feeds`} className={classes.boxWrapper}>
                    <div className={classes.input}><InputLabel htmlFor={`${record.id}-clone-feeds-param`}>Confirm the clone</InputLabel></div>
                    <Button onClick={()=>cbHandleClone(record.guid, guidTo)} className={classes.button}>Let's clone</Button>
                  </div>
                }
            </Fragment>
        );
    }
}

export default withStyles(styles)(CloneFeed);
