import * as React from "react";
import FiltersButton from "./FiltersButton";
import FilterIcon from '@material-ui/icons/FilterList';

const AsideFilters = ({required, editorsFilters, customTemplates, customTemplateGuid, customTemplateSwitcher, contentSyndicationcontents, customSolrFl, camelCaseSwitcher, ...props}) =>  (

        <div style={{ height: '100vh', overflowY: 'scroll', width: 300, margin: '1em', borderRadius: '4px', boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2) ', backgroundColor: '#f6f6f6', textAlign: 'center' }}>
            <FiltersButton
                label="Query filters"
                icon={iconClasses => <FilterIcon style={{ fontSize: 20 }} className={iconClasses.leftIcon} />}
                exclude={required}
                include={props.permissions && props.permissions.role.indexOf('Editor') >= 0 ? [...editorsFilters] : [...window.solr_indexed_fields, 'q', 'account', 'code', 'models', 'video_id', 'playlist_id', 'thread_id', 'jwpplayer']}
                isAside={true}
            />
        </div>

);

export default AsideFilters