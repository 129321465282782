import React from 'react';
import {Datagrid, TextField, Filter, TextInput} from 'react-admin';
import CustomDateField from '../../components/CustomDateField';
import List from '../List';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="user" label={"User"}/>
        </Filter>
    );
};

const ChangeLog = (props) => (
    <>
        <h2>Last purge</h2>
        <List {...props} filters={<Filters />} bulkActionButtons={false}>
            <Datagrid>
                <TextField source="user" />
                <TextField source="url" />
                <TextField source="request" />
                <CustomDateField source="created_at" />
            </Datagrid>
        </List>
    </>
);

export default ChangeLog;
