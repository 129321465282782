import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showNotification as showNotificationAction } from 'react-admin';
import axios from 'axios/index';
import IconButton from './IconButton';
import PublishIcon from '@material-ui/icons/Publish';
import PauseIcon from '@material-ui/icons/Pause';
import { makeBranchedUrl } from '../restClient';
import Cookies from "js-cookie";

class PauseButton extends Component {
    handlePause = () => {
        const { record, showNotification } = this.props;
        const fd = {};

        fd.guid = record.id
        fd.paused = record.paused !== true

        let requestConfig = {
            headers: {
                Authorization: Cookies.get('token'),
                Accept: 'application/json',
                'X-Role': localStorage.getItem('role'),
                'X-ExpiresIn': localStorage.getItem('expiresIn'),
                'X-User-Name': localStorage.getItem('user')
            }
        };

        axios
            .put(makeBranchedUrl(`/api/feeds/${record.id}`), fd, requestConfig)
            .then(response => response.data)
            .then((response) => {
                if (response.error) {
                    showNotification(
                        `${'airedale.pause.error :'}${response.error.exception[0].message}`,
                        'warning',
                    );
                }
                showNotification('airedale.pause.success');
                window.location.reload()
            })
            .catch((thrown) => {
                if (axios.isCancel(thrown)) {
                    console.log('Request canceled', thrown.message);
                } else {
                console.log(thrown);
                }
            });
    };

    render() {
        const { record, minVersion } = this.props;
        return record && record.id ? (
                !minVersion ? (
            <IconButton style={{color:'#d0824b'}}
                variant="outlined"
                icon={classes =>
                    record.paused === true ?
                        <PublishIcon className={classes.leftIcon} size={20} thickness={2} /> :
                        <PauseIcon className={classes.leftIcon} />
                }
                onClick={ this.handlePause }
            >
                { record.paused ? 'Activate' : 'Pause' }
            </IconButton>
                ) : (
            <IconButton style={{color:'#d0824b'}}
                icon={classes =>
                    record.paused === true ?
                        <PauseIcon className={classes.leftIcon} /> :
                        <PublishIcon style={{display:"none"}} />
                }
            >
            </IconButton> )
        ) : null;
                }
}

export default connect(
    null,
    { showNotification: showNotificationAction },
)(PauseButton);
