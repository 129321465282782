import Icon from '@material-ui/icons/Movie';
import List from './List';
import Show from './Show';

const name = 'videos';

export {
    List,
    Show,
    Icon,
    name,
};