import React, { createContext, useContext, useState } from 'react';

const defaultOptions = [
  // { id: "default", name: "Default" },
  ...window.articlesTerritoryChoices,
];
// Create a context for the options list
const OptionsContext = createContext();

// Custom hook to manage the options list
export const useOptions = () => {
  const [options, setOptions] = useState(defaultOptions);
  
  // Function to update options based on selected values
  const updateOptions = (selectedValue,event,optionsState) => {
    var filteredOptions = []
    if(selectedValue && event === "unmount"){
       filteredOptions = optionsState.map(option =>{if(option.id === selectedValue){delete option["disabled"];return option};return option})
    }
    if(selectedValue && event === "mount"){
       filteredOptions = optionsState.map(option => option.id === selectedValue ? {...option,disabled:true} : option)
    }
    setOptions(()=>(filteredOptions));
  };

  return { options, updateOptions };
};

// Provider component to wrap around your application
export const OptionsProvider = ({ children }) => {
  const { options, updateOptions } = useOptions();

  return (
    <OptionsContext.Provider value={{ options, updateOptions }}>
      {children}
    </OptionsContext.Provider>
  );
};

// Custom hook to access options and updateOptions function
export const useOptionsContext = () => useContext(OptionsContext);