import React, { useRef, useState } from 'react';
import {
    AutocompleteInput,
    Button,
    FormDataConsumer,
    ReferenceInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

const AnfJsonCheck = (props) => {

    const [articleId, setArticleId] = useState('');
    const [siteId, setSiteId] = useState('');
    const [jsonData, setJsonData] = useState('');

    const fetchArticleJson = () => {
        fetch("/anf/json?articleId=" + articleId + '&siteId=' + siteId)
            .then(res => res.json())
            .then(
                (result) => {
                    setJsonData(JSON.stringify(result));
                },
                (error) => {
                    console.log(error);
                }
            )
    }

    const handleArticleIdChange = (event, value) => {
        setArticleId(value);
    }

    const handleSiteIdChange = (event, value) => {
        setSiteId(value)
    }

    const textAreaRef = useRef(null);

    const copyJsonToClipBoard = (e) => {
        textAreaRef.current.select();
        document.execCommand('copy');
        alert('Copied!');
      };

    const openApiEndpoint = () => {
        window.open("/anf/json?articleId=" + articleId + '&siteId=' + siteId);
    }
    return (
            props.permissions && props.permissions.checkUserIsSuperAdmin() && <SimpleForm
                toolbar={false}
            >
                <TextInput source="id" label="article Id" value={articleId} onChange={handleArticleIdChange}/>
                <ReferenceInput
                    alwaysOn
                    source="site"
                    reference="publishers"
                    perPage={100}
                    onChange={handleSiteIdChange}
                >
                    <AutocompleteInput optionText="headline" alwaysOn/>
                </ReferenceInput>
                <Button label="Get Json" color="primary" onClick={fetchArticleJson}/>
                <textarea ref={textAreaRef} label="json response" value={jsonData}/>
                <Button label="Copy To Clipboard" color="primary" source="jsonResult"
                    onClick={copyJsonToClipBoard}
                />
                <FormDataConsumer>
                    {({ formData }) => (
                            formData.id && formData.site &&
                            <Button label="open Api endpoint" color="primary" source="openApiEndpoint"
                                onClick={openApiEndpoint}
                            />
                        )
                    }
                </FormDataConsumer>
            </SimpleForm>
    );
};

export default AnfJsonCheck;