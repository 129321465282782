import React from 'react';
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '../../components/IconButton';

export default function SiteLink({ record }) {
    if (!record) {
        return <span />;
    }

    return (
        <IconButton
            color="primary"
            icon={classes => <LinkIcon className={classes.leftIcon} />}
            href={`${record.site_link}/airedale/feeds/${record.id}.${record.feed_type}?_airedale_t=${(new Date()).getTime()}`}
            target="_blank"
        >
            Site
        </IconButton>
    );
};