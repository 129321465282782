import React from 'react';
import {
    TextField, SimpleShowLayout,
} from 'react-admin';
import Show from '../Show';

const TiersShow = props => (
    <Show title="Tier" {...props}>
        <SimpleShowLayout>
            <TextField source="headline" />
        </SimpleShowLayout>
    </Show>
);

export default TiersShow;
