import React from 'react';
import { Datagrid, TextField, Filter, TextInput, SelectInput } from 'react-admin';
import List from '../List';
import { withStyles } from '@material-ui/core/styles';

const styleList = {
    header: {
        backgroundColor: '#f4dee2',
    },
    rowOdd: {
        backgroundColor: '#f6f6f6'
    },
    headerCell: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1em',
        backgroundColor: '#f4dee2',
    }
};

const Filters = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="q" label={"Title"}/>
            <TextInput source="thread_id" label={"Thread Id"}/>
            <SelectInput
                source="site"
                choices={window.sitesXfChoices}
            />
        </Filter>
    );
};
const ForumsList = (props) => {
    const { classes } = props;
    return (
        <List filters={<Filters  />}  {...props} sort={{ field: 'date', order: 'DESC' }} >
            <Datagrid
                classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell,  }}
            >
                <TextField source="thread_id" label="Thread id"/>
                <TextField source="title" />
                <TextField source="url" />
                <TextField source="date" />
            </Datagrid>
        </List>
    );
};

export default withStyles(styleList)(ForumsList);