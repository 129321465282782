import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { FormDataConsumer } from 'react-admin';
import Form from '../Entities/Feeds/Form';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AceEditorInput from "./AceEditorInput";
import { Field } from 'redux-form';

const styles = theme => ({
    root: {
        marginTop: '20px',
    },
    paper: {
        ...theme.mixins.gutters(),
        boxSizing: 'border-box',
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    wrap: {
        wordBreak: 'break-word',
    },
});

const FeedTemplateInput = ({ classes, data }) => (
    <div className={classes.root}>
        <Paper className={classes.paper}>
            <FormDataConsumer>
                {({ formData }) =>
                    formData && formData.field_name_list && (
                        <Fragment>
                            <Typography variant="title" gutterBottom>FIELD-NAME available:</Typography>
                            <Typography gutterBottom>
                                {formData.field_name_list.map(field => (
                                    <span key={field}><samp>{field}</samp>, </span>
                                ))}
                            </Typography>
                        </Fragment>
                    )
                }
            </FormDataConsumer>
            <Typography variant="title" gutterBottom>Macro delimiter:</Typography>
            <Typography gutterBottom>
                A character for the regex formatting of the feed template items.
                This can be all characters except "&",
                letters and numbers characters, (Ex: % or @ or #,, ...). The "%" is the default
                <br />
            </Typography>
            <Typography variant="title" gutterBottom>
                Macros available: <b>%FIELD-NAME%</b>
            </Typography>
            <Typography gutterBottom>
                Example: <code>%locale%</code> OR <code>%guid%</code> OR <code>%items%</code><br />
            </Typography>
            <Typography variant="title" gutterBottom>Macros functions available:</Typography>
            <ul>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%httpGet(url)%</code></strong><br/>
                        Put the response of an url into the current template.<br/>
                        Example: <code>%httpGet('http://airedale.futurenet.com/feeds/custom-template1.custom')%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%IR(thumbnail,method,size)%</code></strong><br/>
                        Example: <code>%IR(thumbnail,rc,320x350)%</code> OR <code>%IR(thumbnail.path,rc,320x350)%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%escape(FIELD-NAME,format)%</code></strong><br/>
                        Example: <code>%escape(headline,js)%</code> OR <code>%escape(headline,html)%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%raw(FIELD-NAME)%</code></strong><br/>
                        Example: <code>%raw(documentUrl)%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%stripTags(FIELD-NAME)%</code></strong><br/>
                        Example: <code>%stripTags(content)%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%toHttps(canonical[,comma-separated-domain-list])%</code></strong><br/>
                        Example: <code>toHttps(canonical)</code> OR <code>toHttps(canonical,\space.com,livescience.com\)</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%description%</code></strong><br/>
                        Example: <code>%description%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%author%</code></strong><br/>
                        Example: <code>%author%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%thumbnail%</code></strong><br/>
                        Example: <code>%thumbnail%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%status%</code></strong><br/>
                        Example: <code>%status%</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>%saving%</code></strong><br/>
                        Example: <code>%saving%</code>
                    </Typography>
                </li>
                { 'article' === data.feed_document_type &&
                    <Form>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%dateFormat(FIELD-NAME,format)%</code>, <code>%Now(FIELD-NAME)%</code></strong><br/>
                                Example: <code>dateFormat(published_date,Y-m-d)</code> OR <code>%Now()%</code> OR <code>%Now(Y-m-d)%</code>
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%link%</code></strong><br/>
                                Example: <code>%link%</code>
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%publish_date%</code></strong><br/>
                                Example: <code>%publish_date%</code>
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%first_created%</code></strong><br/>
                                Example: <code>%first_created%</code>
                            </Typography>
                        </li>
                    </Form>
                }
                { 'videos' === data.feed_document_type &&
                    <Form>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%date%</code></strong><br/>
                                Example: <code>%date%</code>
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%duration%</code></strong><br/>
                                Example: <code>%duration%</code>
                            </Typography>
                        </li>
                        <li>
                            <Typography gutterBottom>
                                <strong><code>%size%</code></strong><br/>
                                Example: <code>%size%</code>
                            </Typography>
                        </li>
                    </Form>
                }
                { 'shopping' === data.feed_document_type &&
                <Form>
                    <li>
                        <Typography gutterBottom>
                            <strong><code>%product_key%</code></strong><br/>
                            Example: <code>%product_key%</code>
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom>
                            <strong><code>%offer.price%</code></strong><br/>
                            Example: <code>%offer.price%</code>
                        </Typography>
                    </li>
                    <li>
                        <Typography gutterBottom>
                            <strong><code>%offer.was_price%</code></strong><br/>
                            Example: <code>%offer.was_price%</code>
                        </Typography>
                    </li>
                </Form>
                }
            </ul>
            <Typography variant="title" gutterBottom>TWIG Filters:</Typography>
            <p>Syntax: <code>[[VALUE|TWIG-FILTER]]</code></p>
            <p>You can use twig filters as documented in the official documentation of twig:
                <a href={'https://twig.symfony.com/doc/3.x/filters/index.html'}> https://twig.symfony.com/doc/3.x/filters/index.html</a><br/>
                It's also possible to combine Macros functions above with the twig filters, see some samples below
            </p>
            <ul>
                <li>
                    <Typography gutterBottom>
                        <strong><code>replace</code></strong><br/>
                        Replace some string/chars by an others<br/>
                        Example 1: <code>{`{'Name': '[[nasa|replace({"nasa": "apple"})]]'}`}</code><br/>
                        Example 2: <code>{`{'headline': '[[%escape(%headline%,js)%|replace({"NASA": "APPLE"})]]'}`}</code>
                    </Typography>
                </li>
                <li>
                    <Typography gutterBottom>
                        <strong><code>upper</code></strong><br/>
                        upper case<br/>
                        Example: <code>{`{'Name': '[[nasa|upper]]'}`}</code>
                    </Typography>
                </li>
            </ul>
            <p><b>List of installed twig filters </b>: <code>{` ${window.twigFilters}`}</code></p>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>List of sorted placeholders avalaible for '{data.feed_document_type}':</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography  className={classes.wrap}>
                        {'article' === data.feed_document_type &&
                            Object.values(window.articlePlacehodersChoices).map(function(slug){ return slug.id; }).toString()
                        }
                        {'videos' === data.feed_document_type &&
                            Object.values(window.videosPlacehodersChoices).map(function(slug){ return slug.id; }).toString()
                        }
                        {'forums' === data.feed_document_type &&
                            Object.values(window.forumsPlacehodersChoices).map(function(slug){ return slug.id; }).toString()
                        }
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Paper>
        <Field labl="Feed template" name="feed_template" source="feed_template" component={AceEditorInput} data={data}/>
    </div>
);

export default withStyles(styles)(FeedTemplateInput);
