import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import { translate as trans } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import IconButton from './IconButton';

class ContentTypeInput extends Component {
    constructor(props) {
        super(props);
        const { input: { value } } = this.props;
        const choices = [
            { id: 'text/html', name: 'text/html' },
            { id: 'application/json', name: 'application/json' },
            { id: 'application/pdf', name: 'application/pdf' },
            { id: 'application/xml', name: 'application/xml' }
        ];
        const isCustom = value &&
            choices.filter(choice => choice.id === value).length === 0;
        if (isCustom) {
            choices.push({ id: value, name: value });
        }
        this.state = {
            customValue: '',
            choices
        };
    }

    componentDidUpdate(prevProps) {
        const { input: { value } } = this.props;
        const { choices } = this.state;
        if (value && value !== prevProps.input.value) {
            // if the content type is not in the predefined choices
            // add it to the list of choices
            const selected = choices.filter(choice => choice.id === value);
            if (!selected.length) {
                this.setState(state => ({
                    choices: [
                        ...state.choices,
                        { id: value, name: value }
                    ]
                }));
            }
        }
    }

    handleChange = event => {
        this.setState({ customValue: event.target.value });
    };

    handleFieldChange = event => {
        const { input: { onChange } } = this.props;
        onChange(event.target.value);
    };

    handleNew = () => {
        const { input: { onChange } } = this.props;
        const { customValue } = this.state;
        onChange(customValue);
    };

    render() {
        const { choices, customValue } = this.state;
        const { input: { value }, translate } = this.props;
        return (
            <div style={{ marginTop: '20px' }}>
                <InputLabel htmlFor="select-content-type">
                    {translate('feeds.content_type')}
                </InputLabel>
                <Select
                    fullWidth
                    value={value}
                    label="Content type"
                    onChange={this.handleFieldChange}
                >
                    {choices.map(choice => (
                        <MenuItem key={choice.id} value={choice.id}>
                            {choice.name}
                        </MenuItem>
                    ))}
                </Select>
                <div style={{ marginTop: '20px' }}>
                    <TextField
                        helperText="New content type"
                        value={customValue}
                        onChange={this.handleChange}
                    />
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={this.handleNew}
                        icon={iconClasses => <AddIcon className={iconClasses.leftIcon} />}
                    >
                        Add new content type
                    </IconButton>
                </div>
            </div>
        );
    }
}

export default trans(ContentTypeInput);
