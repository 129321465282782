import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
// import CustomTextOnSelect from '../components/CustomTextOnSelect';
import { TextInput, SelectInput, LongTextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { getFormValues, Field } from "redux-form";
import { connect } from 'react-redux';
import AceEditorInput from "./AceEditorInput";

const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    buttons: {
        alignSelf: 'center',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: `1fr 1fr ${theme.spacing.unit * 8}px ${theme.spacing.unit * 8}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    },
    fullWidthGridItem: {
        gridColumn: '1 / span 3'
    }
});
/** getSource */
const _isIndexTab = value => {
    let regexIndexArr = /\[([0-9].*)\]$/;
    let match = value.match(regexIndexArr);
    return match;
  };
const getSource = (record = {}, source = "") => {
    return source.split(".").reduce((acc, value) => {
      const reg = _isIndexTab(value);
      if (acc) {
        if (acc[value]) {
          return acc[value];
        }
  
        if (reg) {
            let accKey = value.replace(reg[0], "");
            let accSubKey = parseInt(reg[1], 10);
  
            if (reg && acc[accKey] && acc[accKey][accSubKey]) {
                //if value is index of array and value exist in record
                return acc[accKey][accSubKey];
            }
        }
        return undefined;
      }
      return undefined;
    }, record);
  };

/** fillFieldArrayWithCompareRefs */
const fillArrayFieldIfMissingRefs = (fields,refs,formData) => {
    const compareKeys = fields.map(field=>{
      const source = getSource(formData,field) ? getSource(formData,field) : {};
      return source.key;
      })
    if(refs && Array.isArray(refs)){
        refs.forEach(ref => {
            if(!compareKeys.includes(ref.key)){
                fields.push(ref);
            }
        });
    }
  }

const FullLabelField = ({ record }) => <span>{record.name} ({record.id})</span>;

class CommonDynamicFieldsComponents extends Component {
    componentWillMount(){
        const { fields,defaultValues,formData } = this.props;
        fillArrayFieldIfMissingRefs(fields,defaultValues,formData); //flex-585 apply default values
    }
    render() {
        const { fields, classes, additionnalFields, record, mylabel, helper = "", feedDocumentType, itemValue, itemKey} = this.props;
        let placeholderChoices = window.feedTemplatePlacehodersChoices.concat(window[feedDocumentType + "PlacehodersChoices"]);
        return (
            <div style={{ paddingTop: 15 }}>
                <Typography variant="subheading" gutterBottom><b>{mylabel}</b></Typography>
                    <a target="_blank" rel="noopener noreferrer" href={helper}>
                        <Typography variant="caption" gutterBottom >{helper}</Typography>
                    </a>
                <Paper className={classes.paper}>
                    {fields.length > 0 &&
                        fields.map((itemSource, index) => (
                            <div key={`routing-params-${index}`}>
                                <Typography component="div">
                                    <div className={classes.grid}>
                                        {this.props.fscis_fields &&
                                            <SelectInput
                                                label={`Item #${index + 1} key name`}
                                                source={`${itemSource}.key`}
                                                choices={window.fcsisSearchFields}
                                                optionText={<FullLabelField />}
                                            />
                                        }
                                        {!this.props.fscis_fields && this.props.fields.name !== 'multi_custom_query' && this.props.fields.name !== 'multi_custom_filter_query' && this.props.fields.name !== 'models'&& this.props.fields.name !== 'curatedListIds' &&
                                            <TextInput source={`${itemSource}.key`} label={`Item #${index + 1} ${itemKey ? itemKey : 'key name'}`} />
                                        }
                                        {!this.props.fscis_fields && this.props.fields.name === 'models' &&
                                            <TextInput className={classes.fullWidthGridItem} source={`${itemSource}.key`} label={`Model name #${index + 1}`} />
                                        }
                                        {!this.props.fscis_fields && (this.props.fields.name === 'multi_custom_query' || this.props.fields.name === 'multi_custom_filter_query') &&
                                            <ReferenceInput
                                                label={`Site name #${index + 1}`}
                                                source={`${itemSource}.key`}
                                                reference="publishers"
                                                onChange={this.handleChange}
                                                resource={this.props.resource}
                                                perPage={100}
                                                >
                                                    <AutocompleteInput optionText="headline" optionValue="solr_collection_name" suggestionLimit="3"/>
                                            </ReferenceInput>
                                        }
                                        {!this.props.fscis_fields && this.props.fields.name === 'curatedListIds' &&
                                            <ReferenceInput
                                                label={`Site name #${index + 1}`}
                                                source={`${itemSource}.key`}
                                                reference="publishers"
                                                onChange={this.handleChange}
                                                resource={this.props.resource}
                                                perPage={100}
                                                >
                                                    <AutocompleteInput optionText="headline" optionValue="source" suggestionLimit="3"/>
                                            </ReferenceInput>
                                        }
                                        {(this.props.fields.name === 'multi_custom_query' || this.props.fields.name === 'multi_custom_filter_query') && this.props.fields.name !== 'custom_placeholders' && this.props.fields.name !== 'models' &&
                                            <TextInput source={`${itemSource}.value`} label={`Site #${index + 1} custom value`} />
                                        }
                                        {this.props.fields.name === 'curatedListIds' &&
                                            <TextInput source={`${itemSource}.value`} label={`Curated Id`} />
                                        }
                                        {this.props.fields.name === 'custom_placeholders' &&
                                            <Field labl={`Placeholder #${index + 1} custom value`} name={`${itemSource}.value`} source={`${itemSource}.value`} suggests={placeholderChoices} row={"24px"} component={AceEditorInput} data={this.props.data}/>
                                        }
                                        {this.props.longTextValue && this.props.fields.name !== 'multi_custom_query' && this.props.fields.name !== 'multi_custom_filter_query' && this.props.fields.name !== 'custom_placeholders' && this.props.fields.name !== 'models' && this.props.fields.name !== 'curatedListIds' &&
                                            <LongTextInput source={`${itemSource}.value`}
                                                       label={`Item #${index + 1} value`}/>
                                        }
                                        {!this.props.longTextValue && this.props.fields.name !== 'multi_custom_query' && this.props.fields.name !== 'multi_custom_filter_query' && this.props.fields.name !== 'custom_placeholders' && this.props.fields.name !== 'models' && this.props.fields.name !== 'curatedListIds' &&
                                            <TextInput source={`${itemSource}.value`} label={`Item #${index + 1} ${itemValue ? itemValue : 'value'}`} />
                                        }
                                        {additionnalFields &&
                                            additionnalFields.length > 0 &&
                                            additionnalFields.map((component, idx) => {
                                                return React.cloneElement(component, {
                                                    source: component.props.source ? `${itemSource}.${component.props.source}` : `${itemSource}.param${idx}`,
                                                    key: idx,
                                                    label: component.props.label ? `Item #${index + 1} ${component.props.label} ` : `Item#${index + 1} Param${idx}`,
                                                    record:record }) //cleanContentMiscForFront method in API use "param" id for regexp
                                            })}
                                        <Tooltip title="Remove this item">
                                            <IconButton className={classes.buttons} onClick={() => fields.remove(index)}>
                                                <MinusIcon />
                                            </IconButton>
                                        </Tooltip>
                                        {index === (fields.length - 1) &&
                                            <Tooltip title="Add a new condition">
                                                <IconButton className={classes.buttons} onClick={() => fields.push({})}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </div>
                                </Typography>
                            </div>
                        ))
                    }

                    {fields.length === 0 &&
                        <Button size="small" onClick={() => fields.push({})}>
                            <AddIcon /> Add new Item
                        </Button>
                    }
                </Paper>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    recordLiveValues: getFormValues('record-form')(state)
});

export default connect(mapStateToProps)(withStyles(styles)(CommonDynamicFieldsComponents));