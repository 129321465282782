import { withStyles } from '@material-ui/core/styles';
import React from "react";
import { TagsSelect } from "react-select-material-ui";

const styles = () => ({
    menu: {
        'z-index': '2',
    }
});

class DynamicSelect extends React.Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            articleTag: props.record.articleTag
        }
    }

    handleChange = (data) => {
        const {fields} = this.props;
        fields.removeAll();
        data.map((value) => {
            fields.push(value);
            if(this.options.indexOf(value) === -1) {
                this.options.push(value)
            }
        });
    };

    // If existing Article Tag has string value
    stringValueToArray = (value) => {
        if(typeof value === 'string' && typeof this.props.record.articleTag !== 'undefined' && typeof value !== 'undefined') {
            return this.props.record.articleTag = value.split(',')
        }
    };

  render(){
      const { classes } = this.props;
      let baseOptions = [
          'avnetwork',
          'creative-bloq',
          'cycling-news',
          'digital-camera-world',
          'gamesradar',
          'guitar-world',
          'itproportal',
          'live-science',
          'musicradar',
          'pc-gamer',
          'real-homes',
          'spacecom',
          't3',
          'techradar',
          'techandlearning',
          'toms-guide',
          'top-ten-reviews',
      ];

      if (this.props.commonNodes !== undefined) {
          console.log('toto')
          baseOptions = this.props.commonNodes;
      }

      this.stringValueToArray(this.state.articleTag);

      if(this.props.fields.getAll() !== undefined) {
          this.options = [...baseOptions, ...this.props.fields.getAll()];
      } else {
          this.options = baseOptions;
      }

      return <TagsSelect
          classes={{ root: classes.menu }}
          onChange={this.handleChange}
          label={this.props.label}
          value={this.props.fields.getAll()}
          options={this.options}
          SelectProps={{
              isCreatable: true,
              msgNoOptionsAvailable: "All options are selected",
              msgNoOptionsMatchFilter: "No option matches the filter"
          }}
      />
  }
}

export default withStyles(styles)(DynamicSelect);
