import React from 'react';
import { CardActions, ListButton, ShowButton } from 'react-admin';

const EditActions = ({ basePath, data }) => (
    <CardActions>
        <ShowButton basePath={basePath} record={data} />
        <ListButton basePath={basePath} />
    </CardActions>
);

export default EditActions;
