import { withStyles } from '@material-ui/core/styles';
import React from "react";

const styles = theme => ({
  section_title: {
    fontSize: "1.2rem",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "10px",
  },
  section_title_underline: {
    width: "40px",
    height: "6px",
    backgroundColor: theme.palette.secondary.main,
    marginBottom: "20px",
  },
  info_content: {
    padding: "20px",
    border: "1px solid #E8E8E8",
    marginBottom: "50px",
  },
});

const FormDataContent = ({classes, dataContentTitle = "" , children}) => {
  
  return (
    <>
      <p className={classes.section_title}>{dataContentTitle}</p>
      <div className={classes.section_title_underline}></div>
      <div className={classes.info_content}>{children}</div>
    </>
  );
};

export default withStyles(styles)(FormDataContent);
