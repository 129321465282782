import Icon from '@material-ui/icons/CollectionsBookmark';
import List from './List';
import Edit from './Edit';
import Show from './Show';
import Create from './Create';

const name = 'customtemplates';
let label = "Custom templates";

export {
    List,
    Edit,
    Show,
    Create,
    Icon,
    label,
    name,
};
