import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactGhLikeDiff } from 'react-gh-like-diff';
import fetch from 'unfetch';
import 'react-gh-like-diff/dist/css/diff2html.min.css';

class Diff extends Component {

    constructor(props) {
        super(props);
        this.state = {
            past: '',
            current: '',
            loadedOld: false,
            loadedNew: false
        };
    }

    componentDidMount() {
        fetch('/api/' + this.props.resource + '_archived/' + this.props.item.old)
            .then(function(response){
                return response.json();
            })
            // .then(content => console.log(content))
            .then((content) => {
                if (content.hasOwnProperty('debug_queries')) {
                    content.debug_queries = content.debug_queries.toString().replace(/(http:\/\/)(.*):(.*)@/, '$1$2:***@')
                }
                return JSON.stringify(content, null, 2);
            } )
            .then(past => this.setState({ past: past, loadedOld: true}));

        fetch('/api/' + this.props.resource + '_archived/' + this.props.item.new)
            .then(function(response){
                return response.json();
            })
            .then((content) => {
                if (content.hasOwnProperty('debug_queries')) {
                    content.debug_queries = content.debug_queries.toString().replace(/(http:\/\/)(.*):(.*)@/, '$1$2:***@')
                }
                return JSON.stringify(content, null, 2);
            } )
            .then(current => this.setState({ current: current, loadedNew: true }));
    }

    render() {
        if (this.state.loadedOld === false || this.state.loadedNew === false) {
            return <CircularProgress size={40} thickness={2} />;
        }

        return (
            <ReactGhLikeDiff
                options={{
                    originalFileName: this.props.item.new,
                    updatedFileName: this.props.item.new
                }}
                past={this.state.past}
                current={this.state.current}
            />

        );
    }
}

export default Diff;
