import Icon from '@material-ui/icons/Dvr';
import List from './List';

const name = 'facets';
let label = 'Site Taxonomy';

export {
    List,
    Icon,
    label,
    name,
};