const metricsRegionsFields = [
    { id: 'all', name: 'all regions' },
    { id: 'AE', name: 'AE' },
    { id: 'AU', name: 'AU' },
    { id: 'DE', name: 'DE' },
    { id: 'DK', name: 'DK' },
    { id: 'ES', name: 'ES' },
    { id: 'ES_US', name: 'ES_US' },
    { id: 'FI', name: 'FI' },
    { id: 'FR', name: 'FR' },
    { id: 'GB', name: 'GB' },
    { id: 'IN', name: 'IN' },
    { id: 'IT', name: 'IT' },
    { id: 'NL', name: 'NL' },
    { id: 'NO', name: 'NO' },
    { id: 'NZ', name: 'NZ' },
    { id: 'SE', name: 'SE' },
    { id: 'SG', name: 'SG' },
    { id: 'US', name: 'US' },
    { id: 'ZA', name: 'ZA' }
];

export default metricsRegionsFields;