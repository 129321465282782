import React from 'react';
import PageViewIcon from '@material-ui/icons/Pageview';
import PanoramaIcon from '@material-ui/icons/Panorama';
import IconButton from '../../components/IconButton';

export default function RawPreview({ record, prettify }) {
    if (!record) {
        return <span />;
    }

    if (prettify && ['rss', 'socialflow', 'newswire'].indexOf(record.feed_type) < 0) {
        return <span />;
    }

    const rendered = !!prettify && ['rss', 'socialflow', 'newswire'].indexOf(record.feed_type) >= 0;
    return (
        <IconButton
            color="primary"
            icon={classes => rendered ? <PanoramaIcon className={classes.leftIcon} /> : <PageViewIcon className={classes.leftIcon} />}
            href={`${window.rawFeedHost}/feeds/${record.id}.${record.feed_type}${rendered ? '?prettify=1' : ''}`}
            target="_blank"
        >
            {rendered ? 'Render' : 'Raw'}
        </IconButton>
    );
};