/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import RepeatIcon from '@material-ui/icons/Repeat';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Diff from './Diff';
import DiffButton from './IconButton';
import Grid from "./Grid";
import RestoreButton from "./RestoreButton";

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
};

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class SimpleDialog extends React.Component {
    handleClose = () => {
        this.props.onClose();
    };

    render() {
        const { classes, onClose, ...other } = this.props;

        return (
            <Dialog
                fullScreen
                onClose={this.handleClose}
                aria-labelledby="simple-dialog-title"
                TransitionComponent={Transition}
                {...other}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="title" color="inherit" className={classes.flex}>
                            Diff between v{this.props.item.version-1} and v{this.props.item.version}
                        </Typography>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Diff item={this.props.item} resource={this.props.resource}></Diff>
            </Dialog>
        );
    }
}

SimpleDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class Modale extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = value => {
        this.setState({ open: false });
    };

    render() {
        const { item, record, resource, display_restore_button } = this.props;

        return (
            <div>
                <Grid columns={2}>
                    <DiffButton
                        color="primary"
                        icon={classes => <RepeatIcon className={classes.leftIcon} />}
                        onClick={this.handleClickOpen}
                    >
                        Diff
                    </DiffButton>
                    <RestoreButton
                        record={{
                            guid: record.guid,
                            guidToRestore: item.new,
                            version: item.version,
                            doc_type: record.guid}}
                        resource={resource}
                        display={(item.version > 0 && display_restore_button)}></RestoreButton>
                </Grid>
                <SimpleDialogWrapped
                    item={this.props.item}
                    resource={this.props.resource}
                    open={this.state.open}
                    onClose={this.handleClose}
                />
            </div>
        );
    }
}

export default Modale;
