import React from 'react';
import {
    SimpleForm,
    SelectInput,
    TextInput,
} from 'react-admin';
import { required } from "../validate";
import EditToolbar from '../EditToolbar';

const EditForm = ({ translate, ...props }) => (
    <SimpleForm
        submitOnEnter={false}
        redirect="edit"
        toolbar={<EditToolbar record={props.record} permissions={props.permissions} />}
        {...props}
    >
        <TextInput
            source="headline"
            validate={required()}
        />
        <SelectInput
            defaultValue="standard"
            source="tiers"
            validate={required()}
            choices={[
                { id: 'standard', name: 'Standard' },
                { id: 'premium', name: 'Premium' },
            ]}
        />
    </SimpleForm>
);

export default EditForm;
