const groups = (previousState = {}, { type, payload }) => {
    if (type === 'FEEDS.REGISTER_FILTER_GROUP') {
        return {
            ...previousState,
            ...payload,
        };
    }
    if (type === 'FEEDS.UNREGISTER_FILTER_GROUP') {
        const { [payload]: {}, ...rest } = previousState; // eslint-disable-line no-empty-pattern
        return rest;
    }
    if (type === 'FEEDS.FILTER_GROUP') {
        const { name, hidden } = payload;
        return {
            ...previousState,
            [name]: {
                ...previousState[name],
                hidden,
            }
        };
    }
    return previousState;
};

export { groups };