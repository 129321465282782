import React, {Component} from 'react';
import AceEditor from 'react-ace';
import "ace-builds/src-min-noconflict/mode-html";
import "ace-builds/src-min-noconflict/mode-twig";
import "ace-builds/src-min-noconflict/theme-monokai";
import "ace-builds/src-min-noconflict/ext-language_tools";
import "ace-builds/src-min-noconflict/ext-spellcheck";
import "ace-builds/src-min-noconflict/snippets/html";
import { addField } from 'ra-core';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import { showNotification as showNotificationAction } from "react-admin";
import { connect } from "react-redux";



class AceEditorField extends Component {
    static propTypes = {
        input: PropTypes.object,
        source: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    componentDidMount() {
        const {
            input: {value}
        } = this.props;

        this.setState({value});
    }

    handleValueChange(value) {
        this.setState({value});
        this.props.input.onChange(value);
    };

    handleChange = (event) => {
        const { showNotification } = this.props;

        if (!this.isJsonString(this.state.value)) {
            showNotification('notification.anf_valid_json', 'warning')
        }

    }

    render() {
        const {mode = "html", displayAceLabel = true} = this.props;
        return (
            <FormControl fullWidth={true}>
                {displayAceLabel && <h4>{this.props.label || this.props.source}</h4>}
                <AceEditor
                    mode={mode}
                    theme="monokai"
                    name={this.props.source}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={this.state.value}
                    minLines={1}
                    maxLines={50}
                    onChange={value=>this.handleValueChange(value)}
                    height={"500px"}
                    width={"100%"}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        useWorker: false,
                        tabSize: 2,
                    }} />
                <input type="submit" value="submit" onClick={this.handleChange}/>
            </FormControl>
        );
    }
}

const AceEditorInput = addField(AceEditorField);

AceEditorInput.defaultProps = {
    addLabel: true,
    fullWidth: true,
};

export default connect(null, { showNotification: showNotificationAction }) (AceEditorInput);
//export default AceEditorInput ;