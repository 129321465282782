import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { required } from "../Entities/validate";

const styles = () => ({
    hide: {
        visibility: 'hidden',
        position: 'fixed',
    }
});

class FiltersInputComponent extends Component {
    componentDidMount() {
        const { dispatch, initial, children } = this.props;
        const source = this.source;
        if (!source) {
            return;
        }
        const visible = (!!initial[source] && initial[source].length > 0) || true === initial[source] || typeof initial[source] === 'number' ||
            (children.props.validate && children.props.validate() === required()()) ;
        dispatch({
            type: 'FEEDS.REGISTER_FILTER_GROUP',
            payload: { [source]: { hidden: !visible } }
        });
    }

    componentDidUpdate(prevProps) {
        const { syncErrors, initial, dispatch } = this.props;
        const source = this.source;
        if (!prevProps.syncErrors && syncErrors && !initial['guid']) {
            // creation mode, show required values
            const isRequired = syncErrors && syncErrors[source] === required()();
            const hasInitialValue = !!initial[source];

            dispatch({
                type: 'FEEDS.FILTER_GROUP',
                payload: { name: source, hidden: !(hasInitialValue || isRequired) }
            });
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        const source = this.source;
        if (!source) {
            return;
        }
        dispatch({
            type: 'FEEDS.UNREGISTER_FILTER_GROUP',
            payload: source,
        });
    }

    get source() {
        const { children } = this.props;
        const child = React.Children.only(children);
        return child ? (child.props.source || children.props.name) : null;
    }

    get isHidden() {
        const { filteredGroups } = this.props;
        const source = this.source;
        if (source && filteredGroups && filteredGroups[source]) {
            return filteredGroups[source].hidden;
        }
        // if a child has not a "source" or "name" props
        // we assume it's a custom input, so we show it
        return false;
    };

    render() {
        const { children, classes } = this.props;
        const className = this.isHidden ? classes.hide : '';
        return (
            <div className={className}>
                {children}
            </div>
        );
    }
}

const FiltersInput = connect(state => {
    const { initial, syncErrors } = (state.form && state.form['record-form']) || {};
    return ({
        filteredGroups: state.filteredGroups,
        initial,
        syncErrors,
    });
})(withStyles(styles)(FiltersInputComponent));

export default FiltersInput;