import React from 'react';
import Create from '../Create';
import EditForm from './EditForm';
import EditActions from './EditActions';

const FeedsCreate = props => (
    <Create
        actions={<EditActions permissions={props.permissions} />}
        {...props}
    >
        <EditForm permissions={props.permissions} />
    </Create>
);

export default FeedsCreate;
