const messages = {
    notification: {
        anf_valid_json: 'Your JSON is invalid',
        publishers_form_edit: 'The form is incomplete. Please complete all required fields.'
    },
    resources: {
        feeds: {
            name: 'Feed |||| Feeds',
        },
        feeds_clone:{
            success: 'Feed successfully cloned',
            failed: 'Cloning of feed failed'
        },
        corporations: {
            name: 'Company |||| Companies',
        },
        internaltags: {
            name: 'Internal tag |||| Internal tags'
        }
    },
    feeds: {
        api_key: 'Video Api Key',
        api_secret_key: 'Video Api Secret Key',
        api_params:"API Parameters",
        exclude_tags: 'Exclude Tags',
        guid: 'Guid',
        articleId: 'Article Id',
        curatedListId: 'Curated List Id',
        headline: 'Feed name',
        dek: 'Feed description',
        feed_document_type: 'Feed document type',
        feed_title: 'Feed title',
        link: 'Feed link',
        image: 'Feed image',
        feed_type: 'Format',
        updated_guid: "New Guid (id)",
        locale: 'Feed Locale',
        companies: 'Companies',
        publishers: 'Publishers',
        tiers: 'Tiers',
        nb_results: 'Number of results',
        sitemap_index_threshold: 'Sitemap index threshold',
        syndicate_avoiding_tier_unset: 'Syndicate avoiding tier unset',
        type: 'Format',
        title: 'Query title',
        query: 'Query',
        fields_query_filter: 'Fields Query Filter',
        contentType: 'Editorial content type',
        created_date_from: 'Created date from',
        created_date_to: 'Created date to',
        modified_date_from: 'Last modified date from',
        modified_date_to: 'Last modified date to',
        recent_x_hour: 'The most recent x hours',
        publish_date_from: 'Publish date from',
        publish_date_to: 'Publish date to',
        relative_date: 'Relative date to',
        relative_date_from: 'Relative date from',
        syndicate_at_from: 'Syndicate at from',
        syndicate_at_to: 'Syndicate at to',
        order_field: 'Sort By',
        order_dir: 'Sort Dir',
        exclude_tier: 'You can exclude content by Tiers. Accept Multiple values',
        category: 'Category',
        tags: 'Tags',
        tags_and: 'Tags and',
        internaltags: 'Internal tags',
        highlight: 'Highlight',
        ttl: 'Cache : Time To Live',
        domains: 'Domains',
        tracking: 'Tracking',
        exclude_headline: 'Exclude title',
        exclude_content: 'Exclude content',
        exclude_category: 'Exclude category',
        exclude_keyword: 'Exclude keyword',
        exclude_tag: 'Exclude tag',
        exclude_internaltags: 'Exclude internaltags',
        exclude_highlight: 'Exclude highlight',
        related_links: 'Related links',
        strip_out_poll: 'Strip out poll',
        strip_out_quiz: 'Strip out quiz',
        feed_template: 'Feed template',
        item_template: 'Item template',
        full_content: 'Full content',
        item_separator: 'Item separator',
        item_formative: 'Macro delimiter',
        content_syndication_control_tag: 'Content Syndication Control Tag',
        content_syndication_tag_handle: 'Content Syndication Tag Handle',
        tracking_code: 'Tracking code',
        ad_code: 'Ad code',
        author: 'Author',
        byline: 'Byline',
        camel_case: 'Enable Camel Case',
        camel_case_group: 'Enable Camel Case',
        disable_language: 'Disable Language',
        custom_template: 'Custom template',
        custom_template_switcher_group: "Custom template switcher",
        custom_template_guid_group: "Select a custom template",
        custom_template_switcher: "Custom template switcher",
        custom_template_guid: "Select a custom template",
        custom_filter: "Custom filter  (Use to add extra fields list on feed separated with comma or * to select all fields)",
        custom_fl_group: "Custom filter",
        source_doc_types: 'Source doc types',
        content_type: 'Content type',
        articleCategory: 'Article category',
        exclude_articleBody: 'Exclude article body',
        exclude_articleCategory: 'Exclude article category',
        exclude_articleTag: 'Exclude article tag',
        exclude_articleControlTag: 'Exclude article control tag',
        excludeArticleBlockTypes: 'Exclude Block Types',
        articleAuthor: 'Article Author',
        articleAuthorId: 'Author ID',
        articleBody: 'Article body',
        articleBrand: 'Article brand',
        articleBrandId: 'Article brand ID',
        articleCompany: 'Article company',
        articleLabel: 'Article label',
        articleMediaTitle: 'Article media title',
        articleMetaCanonical: 'Canonical',
        articleMetaDescription: 'Description',
        articleName: 'Article name',
        articleProductText: 'Product Text',
        articleProduct: 'Article Product',
        articleTemplate: 'Article Template',
        text: 'Text',
        tagType: 'Tag Type',
        publishedState: 'Published State',
        productRating: 'Product Rating',
        productName: 'Product Name',
        productInfoValues: 'Product Info Values',
        productInfoLabels: 'Product Info Labels',
        productCategory: 'Product Category',
        canonicalUrl: 'Canonical Url',
        documentUrl: 'Document Url',
        articleType: 'Article Type',
        articleTag: 'Article Tag',
        site: 'Site',
        articleControlTag: 'Article Control Tag',
        articleLanguage: 'Article Language',
        articleTerritory: 'Article Territory',
        articleBodyClean: 'Article Body Clean',
        articleBodyPageBlock: 'Article Body Page Block',
        articleAuthorHandle: 'Article Author Handle',
        articleCompanyHandle: 'Article Company Handle',
        articleCompanyCategoryHandle: 'Article Company Category Handle',
        articleCompanyLocationHandle: 'Article Company Location Handle',
        articleBrandHandle: 'Article Brand Handle',
        articleCategoryHandle: 'Article Category Handle',
        articleVerticalHandle: 'Article Vertical Handle',
        articleVertical: 'Article Vertical',
        articleTagHandle: 'Article Tag Handle',
        articleTagType: 'Article Tag Type',
        articleTagTypeTagHandle: 'Article Tag Type Tag Handle',
        articleTagUrl: 'Article Tag Url',
        articleEventHandle: 'Article Event Handle',
        articleDisciplineName: 'Article Discipline Name',
        articleDisciplineHandle: 'Article Discipline Handle',
        articlePersonUrl: 'Article Person Url',
        articlePersonGroupUrl: 'Article Person Group Url',
        articlePersonGroupName: 'Article Person Group Name',
        articleEventGroupName: 'Article Event Group Name',
        articleEventGroupUrl: 'Article Event Group Url',
        articleLiveCoverageStart: 'Article Live Coverage Start',
        articleLiveCoverageEnd: 'Article Live Coverage End',
        articleVoucherRetailerCategory: 'Article Voucher Retailer Category',
        eventGroupName: 'Event Group Name',
        eventGroupStartDate: 'Event Group Start Date',
        eventGroupEndDate: 'Event Group End Date',
        eventGroupDisciplineName: 'Event Group Discipline Name',
        eventGroupDisciplineHandle: 'Event Group Discipline Handle',
        eventGroupDivision: 'Event Group Division',
        eventGroupCountry: 'Event Group Country',
        eventGroupLocation: 'Event Group Location',
        eventGroupGoverningBodyHandle: 'Event Group Governing Body Handle',
        eventGroupGoverningBody: 'Event Group Governing Body',
        eventGroupGoverningBodyAbbreviation: 'Event Group Governing Body Abbreviation',
        eventGroupFormat: 'Event Group Format',
        eventGroupFormatAbbreviation: 'Event Group Format Abbreviation',
        eventGroupSeries: 'Event Group Series',
        eventGroupParentHandle: 'Event Group Parent Handle',
        personName: 'Person Name',
        personNameFirst: 'Person Name First',
        personNameLast: 'Person Name Last',
        personAttributes: 'Person Attributes',
        personNationality: 'Person Nationality',
        personGroupDivision: 'Person Group Division',
        personGroupId: 'Person Group Id',
        personGroupTypeHandle: 'Person Group Type Handle',
        personGroupName: 'Person Group Name',
        personGroupUrl: 'Person Group Url',
        personGroupDisciplineHandle: 'Person Group Discipline Handle',
        personGroupYear: 'Person Group Year',
        personGroupEstablished: 'Person Group Established',
        excludeArticleTagHandle: 'Exclude Article Tag Handle',
        excludearticleControlTag: 'Exclude Article Control Tag',
        excludeArticleProduct: 'Exclude Article Product',
        excludeArticleProductHandle: 'Exclude Article Product Handle',
        excludeArticleType: 'Exclude Article Type',
        excludeArticleTemplate: 'Exclude Article Template',
        excludeArticleTagType: 'Exclude Article Tag Type',
        excludeArticleTag: 'Exclude Article Tag',
        excludeArticleVerticalHandle: 'Exclude Article Vertical Handle',
        excludeArticleCategoryHandle: 'Exclude Article Category Handle',
        excludeArticleVertical: 'Exclude Article Vertical',
        excludeArticleAuthorHandle: 'Exclude Article Author Handle',
        excludeLegacyId: 'Exclude Legacy Id',
        exclude_terms: 'Exclude Terms',
        excludeArticleId: 'Exclude Article Id',
        articleProductHandle: 'Article Product Handle',
        productCategoryHandle: 'Product Category Handle',
        articleSourceHandle: 'Article Source Handle',
        excludeid: 'Exclude Id',
        productBrand: 'Product Brand',
        productBrandHandle: 'Product Brand Handle',
        articleDistribution: 'Article Distribution',
        widgetTag: 'Widget Tag',
        widgetTagHandle: 'Widget Tag Handle',
        metric_date_value_field: 'Published from now to x hours/days/months...',
        metric_region_field: 'Metrics Data Region',
        metric_time_unit_field: 'Select Time Unit Field',
        metric_type_field: 'Metrics Data Type',
        metric_rank_start_field: 'Metrics Rank Start',
        metric_rank_end_field: 'Metrics Rank End',
        articleUrl: 'Article Url',
        createdDate: 'Created Date',
        legacyId: 'legacy Id',
        modifiedDate: 'Modified Date',
        contentModifiedDate: 'Content Modified Date',
        documentName: 'Document Name',
        documentType: 'Document Type',
        publishedDate: 'Published Date',
        video_id: 'Video Id',
        playlist_id: 'Playlist Id',
        videoTitle: 'Video Title',
        video_duration: 'Video Duration',
        video_view: 'Video View',
        video_size: 'Video Size',
        video_date: 'Video Date',
        thread_id: 'Thread Id',
        search: 'search',
        q: 'Search',
        account: 'Account',
        jwpplayer: 'JwpPlayer',
        update: 'update',
        item_link: 'Item Link',
        item_author: 'Item Author',
        code: 'Code',
        models: 'Models',
        dateTo: 'Date To',
        dateFrom: 'Date From',
        facets: 'Facets',
        searchResponseExcludeFields: 'Search Response Exclude Fields',
        searchResponseType: 'Search Response Type',
        searchResponseHeader: 'Search Response Header',
        searchAlgorithm: 'Search Algorithm',
        start: 'Start',
        rows: 'Rows',
        page: 'Page',
        operator: 'Operator',
        content_syndication : 'Include Content Syndication Contents',
        custom_query: 'Custom Query',
        custom_filter_query: 'Custom Filter Query',
        multi_custom_query: 'Multi Custom Query',
        multi_custom_filter_query: 'Multi Custom Filter Query',
        channel_link: 'Channel Link',
        channel_title: 'Channel Title',
        articles_metrics_data: 'Articles Metrics Data',
        extra_content_url: 'Extra content URL',

        // feed_title_helper: 'Feed title (optional take feed name otherwise)',
        feed_type_helper: 'Template format for the feed output',
        locale_helper: 'This is used for creating the meta feed guid',
        // companies_helper: 'Select Companies allowed to see the feed, Select Standard for all and Premium for the Premium Group',
        // tiers_helper: 'Select The kind of data to be displayed for Tiers : Standard for all and Premium for the Premium Tiers',
        // publishers_helper: 'Select Publishers feeds sources (handled in Publisher Entity',
        nb_results_helper: 'Number of results of your feed',
        sitemap_index_threshold_helper: 'Number of elements from which we have an index of sitemap',
        // syndicate_avoiding_tier_unset_helper: 'syndicate_avoiding_tier_unset',
        title_helper: 'Use this to filter content on titles',
        query_helper: 'Use this to filter on various fields (maximize resultset chance to get results) : Will search in Categories or in Tags, or in Content',
        fields_query_filter_helper: 'Use this to filter on various fields, fetch only content that has fields with the specified values. Example: canonical_override=false;robots="index,follow"',
        contentType_helper: 'Use this to filter specific Editorial Content Type (Note : only available for the moment in tom\'s',
        created_date_from_helper: 'Use this date to filter from a specific creation date',
        created_date_to_helper: 'Use this date to filter to a specific creation date',
        modified_date_from_helper: 'Use this date to filter from a specific modification date',
        modified_date_to_helper: 'Use this date to filter to a specific modification date',
        recent_x_hour_helper: 'The most recent x hours. For example, use this to include all documents published within the most recent 48 hours',
        publish_date_from_helper: 'Use this date to filter from a specific publication date',
        publish_date_to_helper: 'Use this date to filter to a specific publication date',
        relative_date_helper: 'Use this date to filter to a specific publication date (now - this date)',
        syndicate_at_from_helper: 'Use this date to filter from a specific syndicate date (from)',
        syndicate_at_to_helper: 'Use this date to filter from a specific syndicate date (to)',
        order_field_helper: 'Use this to Sort your items : by Publication date, Creation Date or Update Date',
        order_dir_helper: 'Use this to choose the order direction',
        order_field_2_helper: 'Use this to Sort your items : by Publication date, Creation Date or Update Date',
        order_dir_2_helper: 'Use this to choose the order direction',
        category_helper: 'Use this to filter by category : beware that external Publisher feeds',
        tags_helper: 'Use this to filter by tags. The OR operator logic will be used here. Beware that external Pubisher feeds items without tags will not match. Accept Multiple values Comma (,) seperated',
        tags_and_helper: 'Use this to filter by tags. The AND operator logic will be used here. Beware that external Pubisher feeds items without tags will not match. Accept Multiple values Comma (,) seperated',
        // internaltags_helper: 'Use this to filter by internal tags. Beware that external Pubisher feeds items without internal tags will not match. Accept Multiple values Comma (,) separated for AND but also pipe (|) separated for OR',
        highlight_helper: 'Use this to filter according to highlights or internal tags to get only featured and adviced or tsb (Top Story Box content) ex : use tsb for TGU home carousel content',
        ttl_helper: 'Time to live in seconds. %{time} is the minimum',
        camel_case_helper: 'Camel Case mode for Json format fields',
        disable_language_helper: 'Desactivate language and territory',
        custom_template_switcher_helper: 'Display custom template selector',
        domains_helper: 'Use this to filter by domains : *tomsguide.com,*tomshardware.fr if you only want content segmented for tomsguide.com and tomshardware.fr only beware of the wildcard "*" character and the comma separated list',
        tracking_helper: 'Use this to append and anchor with this value to all links. Generally used for Analytics tracking',
        exclude_headline_helper: 'You can exclude content that matches your term here in the title only',
        exclude_content_helper: 'You can exclude content that matches your term here in the content only',
        exclude_category_helper: 'You can exclude categories. Accept Multiple values Comma (,) seperated',
        exclude_keyword_helper: 'You can exclude Keywords. Accept Multiple values Comma (,) seperated',
        exclude_tag_helper: 'You can exclude by Tags. Accept Multiple values Comma (,) seperated',
        // exclude_internaltags_helper: 'You can exclude by Internal Tags. Accept Multiple values Comma (,) seperated',
        // exclude_tier_helper: 'You can exclude content by Tiers. Accept Multiple values',
        // exclude_highlight_helper: 'You can exclude by internal tags and highlights. Accept Multiple values Comma (,) seperated',
        // related_links_helper: 'You can activate or deactivate the related links inside the contents',
        // strip_out_poll_helper: 'When this option is true, the content parser will be empty for the embed poll',
        // strip_out_quiz_helper: 'When this option is true, the content parser will be empty for the embed quizzes',
        full_content_helper: 'Show or hide item contents',
        item_separator_helper: 'Chararacter for separating the feed template items.',
        item_formative_helper: 'A character for the regex format of the feed template items. This can be all characters unless &, letters and numbers characters (Ex: % or @ or #, ...) "%" is the default.',
        content_type_new_item_helper: 'Add new item for content type.',
        source_doc_type_helper: 'You want to display only selected doc_types',
        date_format_helper: 'Format the output date. Ex: y/m/d',
        date_timezone_helper: 'Choose the timezone of the output date. Ex: America/New_York, Europe/Paris',
        trailing_slash: 'Trailing slash',
        affiliate_disclaimer_helper: 'Add Future affiliate disclaimer to items content',
        disable_trailing_slash_helper: 'Remove trailing slash from the item\'s link',
        more_thumbnails_helper: 'Add several resolutions for the item image in \'thumbnails\' tag',
        ttl_tooltip_helper: 'Time to live in seconds',
        strip_tags_helper: 'Strip HTML tags of the displayed content',
        extra_content_url_helper: 'Append extra custom content at the end of the article from a sub feed',
        exclude_embedded_jwp_helper: 'Exclude embedded jwplayer content from articles (MSN)',
        no_hyperlinks_helper: 'Avoid hyperlinks from articles (MSN)',
        use_listing_title_helper: 'Use listing title instead of regular titles (Yahoo)',
        include_embargoed_articles_helper: 'Pull also future articles don\'t published yet',
        affiliation:'Get affiliation link on the feed from the cache',
        affiliation_real_time:'Get affiliation link on the feed on real time',
        curatedListIds: 'Curated multi sites',
        multi_sites_videos: 'Multi sites videos'
    },
};

export default messages;
