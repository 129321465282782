import React from 'react';
import { Datagrid, TextField, EditButton, DeleteButton, ShowButton } from 'react-admin';
import CustomDateField from '../../components/CustomDateField';
import List from '../List';
import { withStyles } from '@material-ui/core/styles';

const styleList = {
    header: {
        backgroundColor: '#f7f1d8',
    },
    rowOdd: {
        backgroundColor: '#efefef'
    },
    headerCell: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1em',
        backgroundColor: '#f7f1d8',
    }
};

const CustomTemplateList = (props) => {
    const { classes, resource, permissions } = props;
    return (
        <List {...props}>
            <Datagrid
                classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell,  }}
            >
                <TextField source="headline" />
                <CustomDateField source="last_modified" label="Last modified" />
                <CustomDateField source="created_at" />
                {permissions && permissions.checkUserCanEdit(resource) ? <EditButton /> : null}
                <ShowButton />
                {permissions && permissions.checkUserCanDelete(resource) ? <DeleteButton /> : null}
            </Datagrid>
        </List>
    );
};

export default withStyles(styleList)(CustomTemplateList);