import Icon from '@material-ui/icons/LibraryBooks';
import List from './List';

const name = 'articles';

export {
    List,
    Icon,
    name,
};
