const validateCustomTemplate = (datas) => {
    const errors = {};

    if (!datas.feed_template) {
        errors.feed_template = ['Required when using "custom" feed.'];
    }
    if (datas.feed_template && datas.feed_template.indexOf('%items%') < 0) {
        errors.feed_template = ['%items% macro is required when using "custom" feed.'];
    }
    if (!datas.item_template) {
        errors.item_template = ['Required when using "custom" feed.'];
    }
    return errors;
};

export default validateCustomTemplate;
