import React from 'react';
import {
    TextField, SimpleShowLayout,
} from 'react-admin';
import Show from '../Show';

const CustomTemplateShow = props => (
    <Show title="Tier" {...props}>
        <SimpleShowLayout>
            <TextField source="headline" />
            <TextField source="item_template" />
            <TextField source="item_separator" />
            <TextField source="item_formative" label={'Macro delimiter'}/>
            <TextField name="content_type"/>
        </SimpleShowLayout>
    </Show>
);

export default CustomTemplateShow;
