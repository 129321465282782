import React from 'react';
import Create from '../Create';
import EditForm from "./EditForm";
import ChangeLog from "./ChangeLog";

const FteCache = (props) => {
    return (
        <>
        <Create {...props}>
            <EditForm permissions={props.permissions} />
        </Create>
            <ChangeLog {...props}/>
        </>
    )
};

export default FteCache;