import React from 'react';
import {Datagrid, TextField, Filter, SelectInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import List from '../List';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styleList = {
        header: {
                backgroundColor: '#57f4cd',
        },
        rowOdd: {
                backgroundColor: '#f6f6f6'
        },
        headerCell: {
                fontWeight: 'bold',
                color: 'black',
                fontSize: '1em',
                backgroundColor: '#57f4cd',
        },
        description: {
                fontWeight: 'bold',
                color: 'black',
                backgroundColor: 'white',
                width: '100%',
                padding: '0 5px',
                margin: '10px 0'
        },
        heading: {
                fontSize: '15px',
                flexBasis: '33.33%',
                flexShrink: 0,
        },
        secondaryHeading: {
                fontSize: '15px',
                color: 'grey',
        },
};

const filtersChoices = [
        { id: 'articleAuthor', name: 'articleAuthor', dek: 'Author name of the article' },
        { id: 'articleAuthorHandle', name: 'articleAuthorHandle', dek: 'Author name of the article (slugified version)' },
        { id: 'articleBrand', name: 'articleBrand', dek: 'Brand associated with an article' },
        { id: 'articleBrandHandle', name: 'articleBrandHandle', dek: 'Brand associated with an article (slugified version)' },
        { id: 'articleCategory', name: 'articleCategory', dek: 'Categories associated with an article' },
        { id: 'articleCategoryHandle', name: 'articleCategoryHandle', dek: 'Categories associated with an article (slugified version)' },
        { id: 'articleControlTag', name: 'articleControlTag', dek: 'Control tags of the article' },
        { id: 'articleDisciplineName', name: 'articleDisciplineName', dek: 'Disciplines associated with an article' },
        { id: 'articleDisciplineHandle', name: 'articleDisciplineHandle', dek: 'Disciplines associated with an article (slugified version)' },
        { id: 'articleDistribution', name: 'articleDistribution', dek: 'Social distribution list of an article' },
        { id: 'articleEventHandle', name: 'articleEventHandle', dek: 'Event associated with an article (slugified version)' },
        { id: 'articleProduct', name: 'articleProduct', dek: 'Products associated with an article' },
        { id: 'articleProductHandle', name: 'articleProductHandle', dek: 'Products associated with an article (slugified version)' },
        { id: 'articleProductText', name: 'articleProductText', dek: 'Product associated with an article (full text version)' },
        { id: 'articleSourceHandle', name: 'articleSourceHandle', dek: 'Source of an article (slugified version)' },
        { id: 'articleTag', name: 'articleTag', dek: 'Tags associated with an article' },
        { id: 'articleTagHandle', name: 'articleTagHandle', dek: 'Tags associated with an article (slugified version)' },
        { id: 'articleType', name: 'articleType', dek: 'Type of the article (news, review, feature, etc...)' },
        { id: 'articleVertical', name: 'articleVertical', dek: 'Verticals associated with an article' },
        { id: 'articleVerticalHandle', name: 'articleVerticalHandle', dek: 'Verticals associated with an article (slugified version)' },
        { id: 'documentType', name: 'documentType', dek: 'Type of the solr document (article, product, author, event-group, etc...)' },
        { id: 'eventGroupDisciplineName', name: 'eventGroupDisciplineName', dek: 'Name of event-group document' },
        { id: 'eventGroupDisciplineHandle', name: 'eventGroupDisciplineHandle', dek: 'Name of event-group document (slugified version)' },
        { id: 'eventGroupDivision', name: 'eventGroupDivision', dek: 'Division of event-group document' },
        { id: 'eventGroupCountry', name: 'eventGroupCountry', dek: 'Country of event-group document' },
        { id: 'eventGroupLocation', name: 'eventGroupLocation', dek: 'Location of event-group document' },
        { id: 'eventGroupFormat', name: 'eventGroupFormat', dek: 'Format of event-group document' },
        { id: 'personNationality', name: 'personNationality', dek: 'Nationality of person document' },
        { id: 'personGroupDivision', name: 'personGroupDivision', dek: 'Nationality of person-group document' },
        { id: 'personGroupTypeHandle', name: 'personGroupTypeHandle', dek: 'Type of person-group document' },
        { id: 'personGroupDisciplineHandle', name: 'personGroupDisciplineHandle', dek: 'Discipline of person-group document (slugified version)' },
        { id: 'productCategory', name: 'productCategory', dek: 'Product category of a document' },
        { id: 'productCategoryHandle', name: 'productCategoryHandle', dek: 'Product category of a document (slugified version)' },
        { id: 'productName', name: 'productName', dek: 'Product name of a document' },
        { id: 'productBrand', name: 'productBrand', dek: 'Product brand of a document' },
        { id: 'productBrandHandle', name: 'productBrandHandle', dek: 'Product brand of a document (slugified version)' },
        { id: 'tagType', name: 'tagType', dek: 'Type of a tag document' },];

const Filters = (props) => {
    return (
        <Filter {...props}>
            <SelectInput
                alwaysOn
                label="Filter"
                choices={filtersChoices}
                source="filter"
            />
            <ReferenceInput
                alwaysOn
                source="site"
                reference="publishers"
                perPage={100}
            >
                <AutocompleteInput optionText="headline" alwaysOn />
            </ReferenceInput>
        </Filter>
    );
};

const FacetsList = (props) => {
        const { classes } = props;
        return (
            <div>
                    <ExpansionPanel>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.heading}>FIELDS LEXICON</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                    <Table aria-label="custom pagination table">
                                            <TableBody>
                                                    {filtersChoices.map((field => (
                                                        <TableRow>
                                                                <TableCell style={{ width: 160 }} align="right">
                                                                        <b>{field.name}</b>
                                                                </TableCell>
                                                                <TableCell style={{ width: 160 }} align="right">
                                                                        {field.dek}
                                                                </TableCell>
                                                        </TableRow>
                                                    )))}
                                            </TableBody>
                                    </Table>
                            </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <div className={classes.description}>Choose a site and a field to have the list of existing values.</div>
                    <List filters={<Filters />} sort={{ field: 'id', order: 'ASC' }} {...props} pagination={null} bulkActionButtons={false}>
                            <Datagrid
                                classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell,  }}
                            >
                                    <TextField source="id" label="Value"/>
                                    <TextField source="count" />
                            </Datagrid>
                    </List>
            </div>
        );
};

export default withStyles(styleList)(FacetsList);