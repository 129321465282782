import React from 'react';
import { Datagrid, TextField, Filter, TextInput, SelectInput } from 'react-admin';
import List from '../List';
import { withStyles } from '@material-ui/core/styles';

const styleList = {
    header: {
        backgroundColor: '#cee0f4',
    },
    rowOdd: {
        backgroundColor: '#f6f6f6'
    },
    headerCell: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1em',
        backgroundColor: '#cee0f4',
    }
};

const Filters = (props) => {
    return (
        <Filter {...props}>
            <TextInput source="q" label={"Title"}/>
            <TextInput source="tags" label={"Tags"}/>
            <SelectInput
                source="account"
                choices={window.jwpAccounts}
            />
        </Filter>
    );
};
const VideosList = (props) => {
    const { classes } = props;
    return (
        <List filters={<Filters  />}  {...props} sort={{ field: 'date', order: 'DESC' }} >
            <Datagrid
                classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell,  }}
            >
                <TextField source="id" />
                <TextField source="title" />
                <TextField source="duration" />
                <TextField source="views" />
                <TextField source="size" />
                <TextField source="date" />
            </Datagrid>
        </List>
    );
};

export default withStyles(styleList)(VideosList);