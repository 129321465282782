import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import {
    ChipField,
    Datagrid,
    DeleteButton,
    EditButton,
    ReferenceArrayField,
    SingleFieldList,
    TextField
} from 'react-admin';
import CloneButton from "../../components/Clone";
import CloneFeed from "../../components/Clone/CloneFeed";
import CustomDateField from '../../components/CustomDateField';
import List from '../List';
import FeedsFilters from './Filters';
import RawPreview from './RawPreview';
import PauseButton from "../../components/PauseButton";
import DeleteButtonWithConfirmation from "../../components/DeleteButtonWithConfirmation";

const styleList = {
    header: {
        backgroundColor: 'Lavender',
    },
    rowOdd: {
        backgroundColor: '#f6f6f6'
    },
    headerCell: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1em',
        backgroundColor: 'Lavender',
    }
};

const rowStyle = (record, index) => {
    let paused = record.paused;

    return {
        borderLeftColor: paused ? "#d03e3e" : "#54c62a",
        borderLeftStyle: "solid",
        borderLeftWidth: "5px",
    };
};

const FeedsList = (props) => {
    const { classes, resource, permissions } = props;
    return (
        <List {...props} filters={<FeedsFilters />}>
            <Datagrid rowStyle={rowStyle} classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell }}
            >
                <TextField source="headline" />
                <TextField label="Type" source="feed_document_type" />
                <TextField label="Format" source="feed_type" />
                <CustomDateField source="last_modified" label="Last modified" toolTipSource="created_at" toolTipCreateBy="created_by" resource={resource} />
                <ReferenceArrayField
                    label="Sites"
                    reference="publishers"
                    source="site"
                >
                    <SingleFieldList>
                        <ChipField source="headline" />
                    </SingleFieldList>
                </ReferenceArrayField>
                {permissions && permissions.checkUserCanEdit(resource) ? <EditButton /> : null}
                <RawPreview label="Preview Actions"/>
                <RawPreview prettify={true} />
                {permissions && permissions.userIsAdminOrSuperAdmin() ? <CloneButton><CloneFeed /></CloneButton> : null}
                {permissions && permissions.checkUserCanDelete(resource) ? <DeleteButtonWithConfirmation /> : null}
            </Datagrid>
        </List>
    );
};

export default withStyles(styleList)(FeedsList);
