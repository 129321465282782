import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { translate as trans } from 'react-admin';
import IconButton from './IconButton';

const styles = () => ({
    label: {
        width: '100%',
    },
    listItem: {
        padding: '0 24px'
    },
    list: {
        width: '100%'
    }
});

class FiltersButtonComponent extends Component {
    constructor(props) {
        super(props);
        const { groups, exclude } = this.props;
        this.state = {
            groups: Object.keys(groups).reduce((acc, name) => ({
                ...acc,
                [name]: {
                    hidden: true,
                    fields: groups[name],
                },
            }), {}),
            exclude: [
                'guid',
                'id',
                ...exclude,
                ...Object.keys(groups).reduce((acc, name) => [
                    ...acc,
                    ...groups[name],
                ], [])
            ],
            anchorEl: null,
        };
    }

    componentDidUpdate(prevProps) {
        const { initial } = this.props;
        const { groups } = this.state;
        if (!prevProps.initial && initial) {
            // first we receive the initial values
            Object.keys(groups).forEach(groupName => {
                // update show group
                // if one of its fields has initially a value
                if (this.state.groups[groupName].hidden === false) {
                    return;
                }
                const hasValue = groups[groupName].fields.some(field => !!initial[field]);
                if (hasValue) {
                    this.updateGroupCheck(groupName);
                }
            });

            let templateSwitcherGroupName = 'custom_template_switcher_group';
            if (groups[templateSwitcherGroupName]) {
                groups[templateSwitcherGroupName].fields.some((field) => {
                    if ('custom_template_switcher' === field && true === initial[field]) {
                        this.updateGroupCheck('custom_template', true);
                    }
                });
            }
        }

        if (this.props.values && prevProps.values && this.props.values !== prevProps.values) {
            this.handleCheckedGroupFilter(prevProps);
        }
    }

    updateGroup = (name, hidden) => {
        this.setState(state => ({
            ...state,
            groups: {
                ...state.groups,
                [name]: {
                    ...state.groups[name],
                    hidden,
                }
            }
        }));
    };

    handleCheckedGroupFilter = (prevProps) => {
        let customTypes = ['sitemap', 'sitemap-news', 'custom'];
        if (typeof this.state.groups.custom_template !== 'undefined' && typeof this.props.values !== 'undefined') {
            let customCond = (customTypes.indexOf(this.props.values.feed_type) !== -1 && customTypes.indexOf(prevProps.values.feed_type) === -1) || (customTypes.indexOf(this.props.values.feed_type) === -1 && customTypes.indexOf(prevProps.values.feed_type) !== -1);
            let jsonCond =  (this.props.values.feed_type === 'json' && prevProps.values.feed_type !== 'json') || (this.props.values.feed_type !== 'json' && prevProps.values.feed_type === 'json');

            let customTemplateSwitcherCond = this.props.values.custom_template_switcher !== prevProps.values.custom_template_switcher;

            if (customCond || customTemplateSwitcherCond) {
                if (customTypes.indexOf(this.props.values.feed_type) !== -1) {
                    let showUseCustomTemplateGuid = (true === this.props.values.custom_template_switcher);
                    this.updateGroupCheck('custom_template_guid_group', !showUseCustomTemplateGuid);
                    this.updateGroupCheck('custom_template', showUseCustomTemplateGuid);
                } else {
                    this.updateGroupCheck('custom_template_guid_group', true);
                    this.updateGroupCheck('custom_template', true);
                }
            }

            if (jsonCond) {
                let showUseCustomFl = (true === this.props.values.custom_filter);
                if ('json' === this.props.values.feed_type) {
                    this.updateGroupCheck('custom_fl_group', showUseCustomFl);
                } else {
                    this.props.values.custom_filter = '';
                    this.updateGroupCheck('custom_fl_group', !showUseCustomFl);
                }
            }

            return;
        }
    };

    handleMenuClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    updateGroupCheck = (name, checkParam) => {
        const { groups } = this.state;
        const { dispatch, filteredGroups } = this.props;
        if (typeof groups[name] !== 'undefined') {
            // if the field we interact is a group of fields
            const check = undefined ===  checkParam ? !groups[name].hidden : checkParam;
            groups[name].fields.forEach(field => {
                dispatch({
                    type: 'FEEDS.FILTER_GROUP',
                    payload: { name: field, hidden: check },
                });
            });
            this.updateGroup(name, check);
            return;
        }
        const check = undefined === checkParam ? !filteredGroups[name].hidden : checkParam;
        dispatch({
            type: 'FEEDS.FILTER_GROUP',
            payload: { name, hidden: check },
        });
    };

    render() {
        const { anchorEl, groups, exclude } = this.state;
        const {
            registeredFields,
            filteredGroups,
            classes,
            translate,
            include,
            label,
            icon,
            isAside
        } = this.props;
        if (!registeredFields) {
            return null;
        }
        const allGroups = { ...filteredGroups, ...groups };
        return (
            <Fragment>
                <IconButton
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenuClick}
                    icon={icon}
                >
                    {label}
                </IconButton>
                <FormControl component="fieldset">
                    {isAside === false &&
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleMenuClose}
                    >
                        {allGroups && Object.keys(allGroups)
                            .filter(groupName => (include ? include.includes(groupName) : true))
                            .filter(groupName => !exclude.includes(groupName))
                            .sort((a,b) => translate(`feeds.${a}`) > translate(`feeds.${b}`) ? 1 : -1)
                            .map(groupName => {
                                return <MenuItem
                                    key={groupName}
                                >
                                    <FormControlLabel
                                        classes={{ root: classes.label }}
                                        control={
                                            <Checkbox
                                                checked={!allGroups[groupName].hidden}
                                                onChange={() => { this.updateGroupCheck(groupName); }}
                                                value={groupName}
                                            />
                                        }
                                        label={translate(`feeds.${groupName}`)}
                                    />
                                </MenuItem>
                            })
                        }
                    </Menu>
                    }
                    {isAside &&
                    <List dense={true} classes={{root: classes.list}}>
                        {allGroups && Object.keys(allGroups)
                            .filter(groupName => (include ? include.includes(groupName) : true))
                            .filter(groupName => !exclude.includes(groupName))
                            .sort()
                            .map(groupName => (
                                <ListItem
                                    key={groupName}
                                    classes={{root: classes.listItem}}
                                >
                                    <FormControlLabel
                                        classes={{root: classes.label}}
                                        control={
                                            <Checkbox
                                                checked={!allGroups[groupName].hidden}
                                                onChange={() => {
                                                    this.updateGroupCheck(groupName);
                                                }}
                                                value={groupName}
                                            />
                                        }
                                        label={translate(`feeds.${groupName}`)}
                                    />
                                </ListItem>
                            ))
                        }
                    </List>
                    }
                </FormControl>
            </Fragment>
        );
    }
}

FiltersButtonComponent.defaultProps = {
    groups: {},
    exclude: [],
    label: 'Menu'
};

const FiltersButton = connect(state => {
    const { initial, registeredFields, values } = (state.form && state.form['record-form']) || {};
    return {
        initial,
        values,
        registeredFields,
        filteredGroups: state.filteredGroups,
    };
}, null)(withStyles(styles)(trans(FiltersButtonComponent)));

export default FiltersButton;
