import React from 'react';
import Edit from '../Edit';
import EditForm from './EditForm';
import EditActions from './EditActions';

const FeedsEdit = props => (
    <Edit
        actions={<EditActions permissions={props.permissions} />}
        {...props}
    >
        <EditForm permissions={props.permissions} />
    </Edit>
);

export default FeedsEdit;
