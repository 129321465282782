import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Datagrid, DeleteButton, EditButton, ShowButton, TextField } from 'react-admin';
import List from '../List';

const styleList = {
    header: {
        backgroundColor: 'Moccasin',
    },
    rowOdd: {
        backgroundColor: '#efefef'
    },
    headerCell: {
        fontWeight: 'bold',
        color: 'black',
        fontSize: '1em',
        backgroundColor: 'Moccasin',
    }
};

const PublishersList = (props) => {
    const { classes, resource, permissions } = props;
    return (
        <List {...props}>
            <Datagrid
                classes={{ rowOdd: classes.rowOdd, thead: classes.header, headerCell: classes.headerCell,  }}
            >
                <TextField source="headline" />
                {permissions && permissions.checkUserCanEdit(resource) ? <EditButton /> : null}
                <ShowButton />
                {permissions && permissions.checkUserIsSuperAdmin(resource) ? <DeleteButton /> : null}
            </Datagrid>
        </List>
    );
};

export default withStyles(styleList)(PublishersList);