import * as Feeds from './Entities/Feeds';
import * as Corporations from './Entities/Corporations';
import * as Publishers from './Entities/Publishers';
import * as InternalTags from './Entities/InternalTags';
import * as Articles from './Entities/Articles';
import * as Videos from './Entities/Videos';
import * as Shopping from './Entities/Shopping';
import * as Forums from './Entities/Forums';
import * as FteCache from './Entities/FteCache';
import * as CustomTemplates from './Entities/CustomTemplates';
import * as SiteFilterValues from './Entities/SiteFilterValues';
import * as AppleNewsDebug from './Entities/AppleNewsDebug';


const resources = [
    Feeds,
    CustomTemplates,
    Corporations,
    Publishers,
    InternalTags,
    Articles,
    Videos,
    Shopping,
    Forums,
    SiteFilterValues,
    FteCache,
    AppleNewsDebug,
];

export default resources;
