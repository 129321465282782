import { AUTH_CHECK, AUTH_LOGIN, AUTH_GET_PERMISSIONS } from 'react-admin';
import Cookies from 'js-cookie';

const checkUserIsSuperAdmin = (roles) => roles.some(role => ['SuperAdmin'].indexOf(role) >= 0);

const checkUserIsAdmin = (roles) => roles.some(role => ['Admin'].indexOf(role) >= 0);

const userIsAdminOrSuperAdmin = (roles = []) =>
    checkUserIsAdmin(roles)
    || checkUserIsSuperAdmin(roles);

const checkUserCanCreate = (roles = [], acl = [], resource = '') =>
    checkUserComesFromCMS()
    || userIsAdminOrSuperAdmin(roles)
    || acl.some(permission => ['.*', `${resource}.*`, `${resource}.create`].indexOf(permission) >= 0);

const checkUserCanRead = (roles = [], acl = [], resource = '') =>
    checkUserComesFromCMS()
    || userIsAdminOrSuperAdmin(roles)
    || acl.some(permission => ['.*', `${resource}.*`, `${resource}.read`].indexOf(permission) >= 0);


const checkUserCanEdit = (roles = [], acl = [], resource = '') =>
    checkUserComesFromCMS()
    || userIsAdminOrSuperAdmin(roles)
    || acl.some(permission => ['.*', `${resource}.*`, `${resource}.update`].indexOf(permission) >= 0);

const checkUserCanRawEdit = roles => userIsAdminOrSuperAdmin(roles);

const checkUserCanDelete = (roles = [], acl = [], resource = '') =>
    userIsAdminOrSuperAdmin(roles)
    || acl.some(role => [`${resource}.*`, `${resource}.delete`].indexOf(role) >= 0);

const checkUserCanHaveAccess = (acl = []) =>
    checkUserComesFromCMS()
    || acl.length > 0;

const checkUserComesFromCMS = () =>
    window.notFromCMS === false
    || undefined === window.notFromCMS;

const checkUserCanSeeListings = (acl = []) =>
    checkUserCanHaveAccess(acl)
    || checkUserComesFromCMS();

export default (type, payload) => {
    if (type === AUTH_LOGIN) {
        if (!payload.token) {
            return Promise.reject();
        }
        const role = !payload.role.length ? ['Regular'] : payload.role;
        // store the token inside a cookie and not localStorage
        // so we can setup the expiration date
        const t = new Date();
        t.setSeconds(t.getSeconds() + payload.expiresIn);
        Cookies.set('token', payload.token, { expires: t });
        localStorage.setItem('role', role.join(','));
        localStorage.setItem('user', payload.user);
        localStorage.setItem('companies', payload.companies.join(','));
        localStorage.setItem('expiresIn', payload.expiresIn);
        localStorage.setItem('user', payload.user);

        // get the set of permissions from the roles
        // without duplicates
        const permissions = [...new Set(role.reduce(function(acc, r) {
            return [...acc, ...window.credentials[r]];
        }, []))];

        localStorage.setItem('permission', permissions.join(','));
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        if (Cookies.get('token')) {
            return Promise.resolve();
        }
        removeSession();
        return Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        if (!Cookies.get('token')) {
            return Promise.reject();
        }
        const role = localStorage.getItem('role');
        const permission = localStorage.getItem('permission');
        const roles = role ? role.split(',') : [];
        const acl = permission ? permission.split(',') : [];
        return (role && permission) ? Promise.resolve({
            role,
            acl,
            userIsAdminOrSuperAdmin: userIsAdminOrSuperAdmin.bind(null, roles),
            checkUserCanRead: checkUserCanRead.bind(null, roles, acl),
            checkUserCanCreate: checkUserCanCreate.bind(null, roles, acl),
            checkUserCanDelete: checkUserCanDelete.bind(null, roles, acl),
            checkUserCanEdit: checkUserCanEdit.bind(null, roles, acl),
            checkUserIsSuperAdmin: checkUserIsSuperAdmin.bind(null, roles),
            checkUserCanRawEdit: checkUserCanRawEdit.bind(null, roles),
            checkUserCanHaveAccess: checkUserCanHaveAccess.bind(null, acl),
            checkUserCanSeeListings: checkUserCanSeeListings.bind(null, acl),
        }) : Promise.reject();
    }
    return Promise.resolve();
};

function removeSession() {
    Cookies.remove('token');
    Cookies.remove('isConnected', { path: '/api' });
    localStorage.removeItem('role');
    localStorage.removeItem('permission');
    localStorage.removeItem('companies');
    localStorage.removeItem('expiresIn');
}

export { removeSession, checkUserComesFromCMS };
export {checkUserIsSuperAdmin};