import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import React, { useState } from 'react';
import {
  BooleanInput,
  TextInput
} from 'react-admin';

const CardJWP = (props) => {

  const [jwpExpanded, setJwpExpanded] = useState(props.record && props.record.jwp ? true : false );

  return(<div>
          <BooleanInput
              source="jwp"
              onClick={() => setJwpExpanded(!jwpExpanded)}
          />
          <Card>
              <Collapse in={jwpExpanded} timeout="auto" >
                  <CardContent>
                      <TextInput
                          source="jwp_api_key"
                          helperText="Used to connect to the JWPlayer Api"
                          style={{ display: 'block' }}
                      />
                      <TextInput
                          source="jwp_api_secret"
                          helperText="Used to connect to the JWPlayer Api"
                          style={{ display: 'block' }}
                      />
                      <br/>
                  </CardContent>
              </Collapse>
          </Card>
      </div>
  )
};

CardJWP.propTypes = {}

export default CardJWP