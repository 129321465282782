const validateFeed = (values) => {
    const errors = {};
    let customTypes = ['custom'];

    if (customTypes.indexOf(values.feed_type) !== -1) {
        if (!values.feed_template && !values.custom_template_guid) {
            errors.feed_template = ['Required when using "custom" feed.'];
        }
        if (values.feed_template && values.feed_template.indexOf('%items%') < 0  && !values.custom_template_guid) {
            errors.feed_template = ['%items% macro is required when using "custom" feed.'];
        }
        if (!values.item_template && !values.custom_template_guid) {
            errors.item_template = ['Required when using "custom" feed.'];
        }
    }

    return errors;
};

export default validateFeed;
