import "ace-builds/src-noconflict/mode-json";
import React from 'react';
import {
    BooleanInput,
    FormDataConsumer,
    FormTab,
    LongTextInput,
    SelectInput,
    TabbedForm,
    TextInput,
    showNotification as showNotificationAction
} from 'react-admin';
import { FieldArray } from 'redux-form';
import ChangelogField from "../../components/ChangelogField";
import CommonDynamicFieldsComponents from "../../components/CommonDynamicFieldsComponents";
import EditToolbar from '../EditToolbar';
import { required } from '../validate';
import LinkListCardNews from "./ANF/LinkListCardNews";
import CardJWP from "./Jwp";
import CardOneSignal from "./OneSignal";
import { connect } from 'react-redux';


const EditForm = ({ translate, showNotification, ...props }) => {
    const validateForm = (values) => {
        const errors = {};

        if (!values.headline || !values.site || !values.source || !values.domain_url || !values.source_type) {
            showNotification('notification.publishers_form_edit', 'warning');
            errors.site = 'The field is required';
        }
        return errors
    };

    return(<TabbedForm
        submitOnEnter={false}
        redirect="edit"
        validate={validateForm}
        toolbar={
            props.permissions && props.permissions.checkUserIsSuperAdmin() &&
                <EditToolbar record={props.record} permissions={props.permissions} />

        }
        {...props}
    >
        <FormTab label="summary">
            <TextInput
                source="headline"
                validate={required()}
                // notification={notification()}
            />
            <TextInput
                source="site"
                validate={required()}
                helperText="Used for the queries (represents the 'site' field on solr)"
            />
            <TextInput
                source="source"
                validate={required()}
                helperText="Used for the queries (represents the 'articleSourceHandle' field on solr)"
            />
            <TextInput
                source="domain_url"
                validate={required()}
                helperText="Used for formatting the canonical urls of the articles"
            />
            <FieldArray mylabel="Domains list" name="domains_urls" component={CommonDynamicFieldsComponents} />
            <SelectInput
                defaultValue="external"
                source="source_type"
                validate={required()}
                choices={[
                    { id: 'internal', name: 'Internal' },
                    { id: 'external', name: 'External' }
                ]}
            />
            <TextInput source="thumbnail" />
            <TextInput source="category" />
            <LongTextInput source="logo_svg" />
            <LongTextInput source="logo_png" />
            <TextInput source="solr_collection_name" label={"Solr collection name"}/>
            <BooleanInput source="trailing_slash"/>
            <LongTextInput source="site_custom_query" label={"Site custom query"}/>
            <CardOneSignal props={props} />
            <CardJWP props={props} />
        </FormTab>
        <FormTab label="ANF">
            <FormDataConsumer>
                {({formData,dispatch}) => 
                    <FieldArray
                        name="apple_news_territories"
                        label={"Territories config for Apple News"}
                        component={LinkListCardNews}
                        formData={formData}
                        dispatch={dispatch}
                        {...props}
                    />
                }
            </FormDataConsumer>
        </FormTab>
        {props.permissions && props.permissions.checkUserCanRead('publishers_log') &&
            <FormTab label="Changelogs" path="changelogs">
                <ChangelogField guid={props.record.guid} />
            </FormTab>
        }
    </TabbedForm>
)};


export default connect(
    null,
    { showNotification: showNotificationAction },
)(EditForm);

// export default EditForm;
