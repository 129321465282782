import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import React, { cloneElement, useEffect } from 'react';

export const styles = theme => ({
    paper: {
        ...theme.mixins.gutters(),
        marginTop: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
    buttons: {
        alignSelf: 'center',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: `1fr 1fr ${theme.spacing.unit * 8}px ${theme.spacing.unit * 8}px`,
        columnGap: theme.spacing.unit,
        width: '100%',
    }
});

const FieldArrayCardFormComponent = ({ fields, classes, myLabel = "item", helper, defaultValues = [], arrayForm = [], ...rest }) => {
    useEffect(() => {
        if (fields.length < 1) {
            defaultValues.forEach(value => fields.push(value))
        }

    }, [])

    return <div style={{ paddingTop: 15 }}>
        <Typography variant="subheading" gutterBottom><b>{myLabel}</b></Typography>
        <a target="_blank" rel="noopener noreferrer" href={helper}>
            <Typography variant="caption" gutterBottom >{helper}</Typography>
        </a>
        <Paper className={classes.paper} >
            {fields.length > 0 &&
                fields.map((itemSource, index) => (
                    <div key={`array-card-form-${index}`}>
                        <Typography component="div">
                            <div className={classes.grid}>
                                {arrayForm.map((component, idx) => cloneElement(component, {
                                    source: component.props.source ? `${itemSource}.${component.props.source}` : `${itemSource}.param${idx}`,
                                    key: idx,
                                    label: component.props.label ? `${component.props.label} Item #${index + 1}` : `Item#${index + 1} Param${idx}`,
                                }))}
                                <Tooltip title={`Remove this ${myLabel}`}>
                                    <IconButton className={classes.buttons} onClick={() => fields.remove(index)}>
                                        <MinusIcon />
                                    </IconButton>
                                </Tooltip>
                                {index === (fields.length - 1) &&
                                    <Tooltip title={`Add a new ${myLabel}`}>
                                        <IconButton className={classes.buttons} onClick={() => fields.push({})}>
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                        </Typography>
                    </div>
                ))
            }

            {fields.length === 0 &&
                <Button size="small" onClick={() => fields.push({})}>
                    <AddIcon /> Add new {myLabel}
                </Button>
            }
        </Paper>
    </div>
}
export default withStyles(styles)(FieldArrayCardFormComponent)